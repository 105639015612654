import React from 'react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
const FindingHd = ({previewType}) => {
    return(
        <TableHead>
            <TableRow>
                <TableCell>SI.No.</TableCell>
                <TableCell>Section</TableCell>
                <TableCell>Damage Type</TableCell>
                {previewType === 'brief'  ?
                    <>
                        <TableCell>Damage Dimension</TableCell>
                        <TableCell>Damage Within Limit</TableCell>
                    </>:null
                }
                <TableCell>Description</TableCell>
                {previewType === 'brief' ?
                    <>
                        <TableCell>Classification</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Repair Type</TableCell>
                        <TableCell>Threshold/Interval</TableCell>
                        <TableCell>Attachment(s)</TableCell>
                    </>:null
                }
                <TableCell align='right'>Action</TableCell>
            </TableRow>
        </TableHead>
    )
}
export default FindingHd;
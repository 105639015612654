import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Row, Col , Modal, ModalHeader, ModalBody } from 'reactstrap';
import moment from 'moment';
import DatePicker from "react-datepicker";
import InfiniteScroll from 'react-infinite-scroll-component';
import { ManTrackerSideInfo, MonthAndDateHeader, StatusDetails,UtilizationGraph } from '../components';
import TableFilter from '../../../../shared/TableFilter';
import { sGlobalConstantAc } from '../../../../shared/actionCreators';
import { removeEmptyKey, getLocalStorageInfo } from '../../../../utils';
import { ToolTipHover, ListLoader, NoRecordFound, ExportFiles } from '../../../../shared';
import { fetchManPowerTrackerAc,downLoadTrackerFormAc, fetchManPowerTrackerExpansionAc,fetchManPowerTrackerUtilizationAc } from '../actionCreators';
import {imgStoragePath} from '../../../../constants'
import { UserInfoModal } from '../../Elements';
import showUlilIcon from '../../../../shared/assets/img/show_util.svg';

class ManPowerTracker extends Component {
  constructor(props){
    super(props);
    this.state = {
      months: [],
      lhWidth: 0,
      modal: false,
      height: 0,
      windows:false,
      colWidth : 80,
      queryParam:{},
      userDetail: {
        flag: false,
        data: {}
      },
      page: 1
    }
    this.toggle = this.toggle.bind(this);
  }

  moveNextPage = () =>{

    this.setState({
      page: this.state.page + 1
    })
    this.props.fetchManPowerTrackerDetailsExpansion({...this.state.queryParam, city: this.state.queryParam.location, page: this.state.page})
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  getUserInfo = (user) =>{
    this.setState({
      userDetail: {
        flag: !this.state.userDetail.flag,
        data: user
      }
    })
  }

  componentDidMount(){
    this.props.fetchManPowerTrackerDetails({page: 1});
    this.props.fetchProjectDepartment({constant_types:['project_departments','user_category']});
    let lhWidth = document.body.contains(document.getElementById('lh-project-list')) ? document.getElementById('lh-project-list').clientHeight : 0 ;
		this.setState({
			lhWidth: lhWidth
    })
    this.setState({
       height : window.innerHeight - (41 + 78 + 112)
    });
    if(navigator.appVersion.indexOf("Win")!=-1){
      this.setState({
        windows : true
     });
    }
    document.querySelector('body').style.overflow = 'hidden';
  }

  componentWillReceiveProps(nextProps){
    this.generateMonth(moment(nextProps.manPowerTrackerDtls.start_date), moment(nextProps.manPowerTrackerDtls.end_date))
 }

 componentWillUnmount(){
  document.querySelector('body').style.overflow = 'auto';
}

  generateMonth = (startDate, endDate) => {
		let monthDiff = endDate.diff(startDate, 'months', true);
		var timeValues = [];
		while(endDate > startDate || startDate.format('M') === endDate.format('M')) {
			let obj = {};
			obj = {month: startDate.format('MMMM'), year: startDate.format('YYYY')};
				timeValues.push(obj);
				startDate.add(1,'month');
		}
		 this.setState(prevState => ({
			...prevState,
		 months: timeValues
	 }))
	}

  getProjectSlots = (csd, ced, psd, ped) => {
		/*
		PSD => Proejct Start Date
		PED => Proejct End Date
		CSD => Calendar Start Date
		CED => Calendar End Date
		*/
		let initialSpace= 0;
		let finalSpace= 0;
		let duration = 0;
    let dataObj = {}
		if (( (moment(psd) == moment(csd)) && (moment(psd) == moment(ced)) )) {
			duration = moment.duration(moment(psd).diff(moment(ped)))
			initialSpace = moment.duration(moment(psd).diff(moment(csd))) ;
			finalSpace = moment.duration(moment(ped).diff(moment(ced))) ;
			dataObj = { duration: (duration.asDays()+1), initialSpace: initialSpace.asDays(), finalSpace: finalSpace.asDays()}
		 return dataObj;
	 }else if((moment(psd) >= moment(csd)) && (moment(ped) <= moment(ced))){
			 duration = moment.duration(moment(ped).diff(moment(psd)))
			 initialSpace = moment.duration(moment(psd).diff(moment(csd))) ;
			 finalSpace = moment.duration(moment(ced).diff(moment(ped))) ;
			 dataObj = { duration: (duration.asDays() + 1), initialSpace: initialSpace.asDays(), finalSpace: finalSpace.asDays()}
			return dataObj;
		}else if ((moment(psd) <= moment(csd)) && (moment(ped) <= moment(ced) )) {

			duration = moment.duration(moment(ped).diff(moment(csd)))
			 initialSpace = moment.duration(moment(psd).diff(moment(csd))) ;
			 finalSpace = moment.duration(moment(ped).diff(moment(ced))) ;
			 dataObj = { duration: (duration.asDays()+1), initialSpace: initialSpace.asDays(), finalSpace: finalSpace.asDays()}
			return dataObj;
    }else if ((moment(psd) <= moment(csd)) && (moment(ped) >= moment(ced) )) {

			duration = moment.duration(moment(ced).diff(moment(csd)))
			 initialSpace = moment.duration(moment(csd).diff(moment(csd))) ;
			 finalSpace = moment.duration(moment(ced).diff(moment(ced))) ;
			 dataObj = { duration: (duration.asDays()+1), initialSpace: initialSpace.asDays(), finalSpace: finalSpace.asDays()}
			return dataObj;
		}else if (( (moment(psd) >= moment(csd)) && (moment(ped) >= moment(ced)) )) {
			duration = moment.duration(moment(ced).diff(moment(psd)))
			 initialSpace = moment.duration(moment(psd).diff(moment(csd))) ;
			 finalSpace = moment.duration(moment(ced).diff(moment(ced))) ;
			 dataObj = { duration: (duration.asDays()+1), initialSpace: initialSpace.asDays(), finalSpace: finalSpace.asDays()}
			return dataObj;
		}
		else if ((moment(psd) >= moment(csd)) && (moment(ped) <= moment(ced))) {
			duration = moment.duration(moment(ped).diff(moment(csd)))
			 initialSpace = moment.duration(moment(psd).diff(moment(csd))) ;
			 finalSpace = moment.duration(moment(ped).diff(moment(ced))) ;
			 dataObj = { duration: (duration.asDays()+1), initialSpace: initialSpace.asDays(), finalSpace: finalSpace.asDays()}
			return dataObj;
		}
		else{
			return null
		}
  }

  getDates = (startDate, endDate) => {
    let dates=[], currentDate = moment(startDate);
    while(moment(endDate) >= moment(currentDate)){
      dates.push(moment(currentDate).format('YYYY-MM-DD'));
      currentDate = moment(currentDate).add('days', 1).format('YYYY-MM-DD');
    }
    return dates
  }


  changeFilterFn=(filter)=>{
    this.setState({
      queryParam: filter,
      page: 1
    });
    this.props.fetchManPowerTrackerDetails({...filter,city:filter.location, page: 1});
  }

  render(){
    const menuList = {
      'Status': {
        'inputType': 'dropdown',
        'keyParam': 'user_type',
				'placeholder': 'Select status',
				'options': [{id: 'available', name: 'Available'}, {id: 'not_available', name: 'Not Available'}, {id: 'deployed', name: 'Deployed'}],
        'labelKey': 'name',
        'valueKey': 'id',
        'label': 'Status',
			},
      'User': {
        'inputType': 'text',
        'keyParam': 'user',
        'placeholder': 'Search',
        'label': 'Resource'
      },
      'Project Name': {
        'inputType': 'text',
        'keyParam': 'project_name',
        'placeholder': 'Search',
        'label': 'Project Name'
      },
      'Location': {
        'inputType': 'text',
        'keyParam': 'location',
        'placeholder': 'Search',
        'label': 'Location'
      },
      'Date Range': {
        'inputType': 'date',
        'keyParam': 'date',
        'label': 'Date'
      },
      'Department': {
        'inputType': 'dropdown',
        'keyParam': 'department',
				'placeholder': 'Select Department',
				'options': this.props.techConstants? this.props.techConstants.filter(item => item.type == 'project_departments'):[],
        'labelKey': 'label',
        'valueKey': 'value',
        'label': 'Department',
        'multi' : true
      },
      'Category': {
        'inputType': 'dropdown',
        'keyParam': 'category',
				'placeholder': 'Select Category',
				'options': this.props.techConstants? this.props.techConstants.filter(item => item.type == 'user_category'):[],
        'labelKey': 'label',
        'valueKey': 'value',
        'label': 'Category',
        'multi' : true
			},
    }
    const { manPowerTrackerDtls, trackerLoader,exportTrackerLoader,techConstants ,utilizationData} = this.props;
    
    return(
      <div className="tracker-block">
        <div className="title-block">
            <h1>Trackers</h1>
            {getLocalStorageInfo().user.is_root_user || (getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('MR')) ? <p>Offers a view of the Projects status and Manpower status as applicable</p> : <p>Offers a view of the Projects status as applicable</p>}
        </div>
        <ul className="list-inline project-heading">
         { getLocalStorageInfo().user.is_root_user || (getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('GR')) || (getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('R')) ?
          <li className="list-inline-item">
            <Link to={!trackerLoader ? '/technical/projects/tracker':''}>PROJECT</Link>
          </li>:null}
          <li className="list-inline-item">
            <Link className="active">MAN POWER</Link>
          </li>
          {/* { getLocalStorageInfo().user.is_root_user || (getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('CR')) ?
					<li className="list-inline-item">
					<Link to={!trackerLoader ? '/technical/cor/tracker':''} >COR Tracker</Link>
			   	</li>:null
			   	} */}
        </ul>
        {exportTrackerLoader ? <ListLoader />:null}
        <div className="project-tracker">
        <div className="add-bulk-operations row" style={{margin:'0px 15px'}}>
          <div className="col-md-10">
            <ul className="list-inline flex-centered">
              <li className="list-inline-item operation-cta">
                <ExportFiles exportFile={(fileType) => this.props.projectTrackerExport('manpower-tracker',fileType,'ManPowerTracker',this.state.queryParam)} files={[{img:'xls_icon.png', label: 'XLS', type:'xls'}]} />
              </li>
              <li style={{marginLeft:'15px'}} >
              <p> <b>{manPowerTrackerDtls.pagination && manPowerTrackerDtls.pagination.total ? manPowerTrackerDtls.pagination.total :'0'}</b> Resources</p>
              </li>
            </ul>
          </div>
          <div className="col-md-2">
            <StatusDetails/>
          </div>
              </div>
              <div className="add-bulk-operations row" style={{margin:'0px 15px',padding:'0px'}} >
              <div className="table-list-filter col-md-10" style={{padding:'0px'}}>
              <TableFilter
                clearFilterValue={this.clearFilterValue}
                filter={{menuList: menuList, appliedFilter: {}}}
                changeFilterFn={this.changeFilterFn}
                queryParamsEnabled={false}
             />
            </div>
            <div className="col-md-2 d-none d-sm-block" style={{padding:'0px',marginTop:'20px' }}>
              <p onClick={()=>{this.toggle(); this.props.fetchManPowerUtilization(this.state.queryParam)}}  style={{textAlign:'right',fontSize:'12px',color:'#3f51b5', marginRight:'10px',cursor:'pointer'}}><img width="13" style={{marginRight: '3px'}} src={showUlilIcon} alt=""/>Show Utilization</p>
            </div>
            </div>
          { trackerLoader ? <ListLoader />
            :
            <div className="project-tracker-wrapper">
              <div className="flex-not-centered project-tracker-block header-sep-block">
                <div className="inner-width-block">
                  <div className="row-header flex-not-centered" style={{ height: '74px'}}>
                    <div id="lh-project-list" className="flex-not-centered spacing-cls project-heading-block text-right" style={{ flexFlowL: 'nowrap'}}>
                      { [{label: 'Name of Staff', width: '242.5px'}, {label: 'Projects', width: '131px'}, {label: 'Status', width: '111px'}].map((item, index) =>
                        <div className="innerheading-block flex-centered" style={{ width: item.width, flexBasis: item.width}}>
                          <p className="para-ui ">{item.label}</p>
                        </div>
                      )}
                    </div>
                    <div style={{ width: (window.innerWidth - 428) + 'px', overflow: 'hidden' }}>
                      <MonthAndDateHeader
                       	months ={this.state.months}
                        projectTrackerDtls={manPowerTrackerDtls}
                        DateList = {this.getDates(manPowerTrackerDtls.start_date, manPowerTrackerDtls.end_date)}
                        colWidth = {this.state.colWidth}
                        lhWidth = {this.state.lhWidth}
                      />
                    </div>
                  </div>
                </div>
              </div>
             { Object.keys(manPowerTrackerDtls).length ?
              <div style={{ height: window.innerHeight - 290 + 'px', overflow:'auto', overflow: 'auto', marginBottom: '60px' }} className="scrollbar-hidden">
                <InfiniteScroll
                  dataLength={manPowerTrackerDtls.users ? manPowerTrackerDtls.users.length : 0} //This is important field to render the next data
                  next={() => this.moveNextPage()}
                  hasMore={manPowerTrackerDtls.users && manPowerTrackerDtls.pagination && manPowerTrackerDtls.users.length < manPowerTrackerDtls.pagination.total}
                  height={window.innerHeight - 320}
                  className="scrollbar-hidden"
                  >
                <div className="flex-not-centered project-range-block">
                  <ManTrackerSideInfo
                    userList={manPowerTrackerDtls.users}
                    getUserInfo = {(user) => this.getUserInfo(user)}
                  />
                <div style={{ width: (window.innerWidth - (this.state.windows?640:610)) + 'px', overflow: 'auto'}}>
                    <div className="inner-width-block" style={{width:  (window.innerWidth - (this.state.windows?640:610)) + 'px'}}>
                      { manPowerTrackerDtls.users.map((user,index) => {
                        return(
                          <div key={index} className="rh-project-block" style={{ width: (this.getDates(manPowerTrackerDtls.start_date, manPowerTrackerDtls.end_date).length * this.state.colWidth) + 'px' }}>
                            { this.getDates(manPowerTrackerDtls.start_date, manPowerTrackerDtls.end_date).map((date, index) =>
                              <div className="inner-row-block ">
                                <ul className="list-inline">
                                  <li style={{width: this.state.colWidth+'px', margin: 0, textAlign: 'center',height: user.projects && user.projects.length && user.projects.length > 2 ?  (25 * user.projects.length+30)+'px' : '90px'}}  className="list-inline-item inner-square-block"></li>
                                </ul>
                              </div>
                            )}
                            {user.projects.map((project,projIndex)=> {
                              let projectDays='';
                              projectDays = this.getProjectSlots(manPowerTrackerDtls.start_date, manPowerTrackerDtls.end_date, project.start_date, project.expected_end_date)
                              return (
                                <span id ={`project-status-${project.id}-${user.id}`} className= {`project-dates status${project.status}`}
                                  style={{
                                    display: 'inline-block',
                                    width: projectDays && projectDays.duration > 0 ? projectDays.duration * this.state.colWidth : 0,
                                    left: projectDays && projectDays.initialSpace > 0 ? projectDays.initialSpace * this.state.colWidth : 0, right: projectDays && projectDays.finalSpace > 0 ? (projectDays.finalSpace * this.state.colWidth) : 0, top: (25 * projIndex +15)+'px' }}>
                                  { projectDays && projectDays.duration >  1 ?
                                    <b className={projectDays.duration}>{ (project.start_date > manPowerTrackerDtls.start_date  || project.start_date < manPowerTrackerDtls.end_date) ? moment(project.start_date).format('DD MMM, YYYY'): '--'} </b>
                                    : null
                                  }
                                  { projectDays && projectDays.duration > 4 ?
                                    <b style={{left:this.state.colWidth+5+'px'}}>{projectDays && projectDays.duration > 2 && project.location ? project.location:''} </b>
                                    : null
                                  }
                                  { projectDays && projectDays.duration <= 4 && projectDays.duration > 2 ?
                                    <b style={{left:this.state.colWidth+5+'px'}}>{projectDays && projectDays.duration > 2 && project.location ? project.location.length > 25 ? project.location.substring(0,22)+'...':project.location:''} </b>
                                    : null
                                  }
                                  { projectDays && projectDays.duration <= 2 ?
                                  <b style={{left:this.state.colWidth+5+'px',display:'none'}} ><i className="fa fa-map-pin"></i></b>
                                    : null
                                  }

                                  <img  src={imgStoragePath + 'flight_white.png'} alt="img"/>
                                </span>
                              )
                            })}
														{ user.projects.map((project,projIndex) =>
															<ToolTipHover placement="top" tagetId={`project-status-${project.id}-${user.id}`}>
                                 {moment(project.start_date).format('DD MMM, YYYY')} to {moment(project.expected_end_date).format('DD MMM, YYYY')}
                                 <p>{project.location ? ' '+project.location:''}</p>
											 				</ToolTipHover>
														)}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </InfiniteScroll>
              {manPowerTrackerDtls.users?manPowerTrackerDtls.users.length?null:<NoRecordFound/>:null}
              </div>:null
             }
            </div>
          }
        </div>
        {
          trackerLoader ? <ListLoader/> : null
        }
        {
          this.props.infiniteLoader ?
            <div style={{paddingLeft: '45%'}} className="data-room-expansion-loader"><img src={imgStoragePath + "spinner.gif"}/></div>
          :null
        }
        {
          this.state.userDetail.flag ?
          <UserInfoModal
            userDetail = {this.state.userDetail}
            closeUserDetailModal = {this.getUserInfo}
          />
          :null
        }
         <Modal centered={true} isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="lg">
          <ModalHeader toggle={this.toggle}>Utilization</ModalHeader>
        <ModalBody style={{minWidth: '800px', minHeight: '500px'}}>
            <div>
              {
                !exportTrackerLoader ?
                <UtilizationGraph
                           symbol="Hours Remaining :"
                           yType="percentage"
                           toolTipText="Percentage (%)"
                           xLabel="Duration (Months)"
                           yLabel="Manpower Usage (%)"
                           legend="Hours"
                           type="low"
                           title="Life On Wings Analysis"
                           color='#FEF7D1'
                           lineColor="#F8D41C"
                           fetchManPowerUtilization={(filters) => this.props.fetchManPowerUtilization({...filters,...this.state.queryParam})}
                           startDate= {utilizationData? utilizationData.start_date:''}
                           events={[]}
                           plots={!exportTrackerLoader && utilizationData && utilizationData.utilizations ?utilizationData.utilizations:[]} />
                :null
              }
            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
	manPowerTrackerDtls : state.TrackerReducer.manPowerTrackerDtls,
  trackerLoader: state.TrackerReducer.trackerLoader,
  infiniteLoader : state.TrackerReducer.infiniteLoader,
  exportTrackerLoader:state.TrackerReducer.exportTrackerLoader,
  techConstants:state.sharedReducers.techConstants,
  utilizationData:state.TrackerReducer.utilizationData,
});
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
    fetchProjectDepartment: (type) => dispatch(sGlobalConstantAc(type)),
    projectTrackerExport: (url,type,name,queryParam={}) =>dispatch(downLoadTrackerFormAc(url,type, name, queryParam)),
		fetchManPowerTrackerDetails : (queryParam={}) => dispatch(fetchManPowerTrackerAc(ownProps, queryParam)),
    fetchManPowerTrackerDetailsExpansion : (queryParam={}) => dispatch(fetchManPowerTrackerExpansionAc(ownProps, queryParam)),
    fetchManPowerUtilization:(filter) => dispatch(fetchManPowerTrackerUtilizationAc(filter)),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(ManPowerTracker);

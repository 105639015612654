import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core';
import { DeletePopUp, PageLoader } from '../../../shared_elements';
import { ImageSlider, EditImageDialog } from '../../Elements';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { checkApiStatus, dataURItoBlob } from '../../../utils_v2';
import { globalDeleteService, globalPutService } from '../../../utils_v2/globalApiServices';
const Attachments = ({params, editAttachments, toggleModalFn, getResponseBack, mode='edit'}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [deleteImage, setDeleteImage] = useState({modal:false, data:null})
    const [imgAnnotation, setImgAnnotation] = useState({modal:false, data:null})
    const [sliderInfo, setSliderInfo] = useState({modal:false, images:[], startIndex:null});
    const updateSlider = (pictures, title, index) => {
        setSliderInfo({modal:true, title: '', startIndex:index, images:pictures.map(item => {return {original:item.image, thumbnail:item.image}}),})
    }
    const onDeleteImage = () => {
        globalDeleteService(`technical/v2/workorder/${params.workOrderSlug}/inspection-finding-picture/${deleteImage.data.id}/`)
        .then(response => {
            if(checkApiStatus(response)){
                setDeleteImage({modal:false, data:null});
                getResponseBack();
                enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            }else{
                enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            }
        })
    }
    const annotateGenPicture = (file) => {
        dataURItoBlob(file).then(editedImage => {
            let formData = new FormData();
            formData.append('file', editedImage);
            globalPutService(`technical/v2/workorder/${params.workOrderSlug}/inspection-finding-picture/${imgAnnotation.data.id}/`, formData)
            .then(response => {
                if(checkApiStatus(response)){
                    setImgAnnotation({modal:false, data:null})
                    getResponseBack();
                    enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                }else{
                    enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                }
            })
        });
    }
    return (
        <>
            <Dialog
                open={editAttachments.modal}
                onClose={toggleModalFn}
                className='project-management-modal'
                maxWidth='md'
            >
                <DialogTitle id="scroll-dialog-title">
                    Finding Attachment(s)
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div style={{minWidth:'440px'}}>
                        <ul className='list-inline'>
                            {editAttachments.data.images.map((item, index) => 
                                <li className='list-inline-item' style={{position:'relative'}}>
                                    <img style={{width:'150px', height:'150px'}} src={item.image} alt='' />
                                    <span style={{position:'absolute', top:'5px', right:'5px', background:'#fff', padding:'4px'}}>
                                        <VisibilityIcon onClick={() => updateSlider(editAttachments.data.images, '', index)} color="primary" fontSize="small" />
                                        { mode !== 'view' ?
                                            <>
                                                <EditIcon onClick={() => setImgAnnotation({modal:true, data:item})} color="primary" fontSize="small" />
                                                <DeleteOutlineIcon onClick={() => setDeleteImage({modal:true, data:item}) } color="secondary" fontSize="small" />
                                            </>:null
                                        }
                                    </span>
                                </li>
                            )}
                        </ul>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size="small">Close</Button>
                </DialogActions>
            </Dialog>
            { deleteImage.modal ?
                <DeletePopUp
                    modal={deleteImage.modal}
                    toggleModalFn={() => setDeleteImage({modal:false, data:null})}
                    title="Delete Image"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={onDeleteImage}
                />:null
            }
            { imgAnnotation.modal ?
                <EditImageDialog
                    imgAnnotation={imgAnnotation}
                    saveAnnotateImg={(file) => annotateGenPicture(file)}
                    toggleModalFn={() => setImgAnnotation({modal:false, data:null, sectionId:null})}
                />:null
            }
            { sliderInfo.modal ?
                <ImageSlider
                    sliderInfo={sliderInfo}
                    toggleModalFn={() => setSliderInfo({modal:false, title:'', startIndex:null, images:[]})}
                />:null
            }
        </>
    );
}
export default withRouter(Attachments);
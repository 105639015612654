import React, { useState, useEffect, Fragment } from 'react';
import { browserHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Paper, Grid, Button, Tabs, Tab } from '@material-ui/core'
import { FormWrapper, FormSkeletonLoader } from '../../Elements';
import { ExportManu, EmptyCollection, PageLoader } from '../../../shared_elements';
import { Comments, Attachment, IssueTitle, OtherInformation, ContentExpansion, QuickIssueList } from '../components'
import { checkApiStatus, removeEmptyKey } from '../../../utils_v2';
import { globalGetService, globalPostService } from '../../../utils_v2/globalApiServices';
const queryString = require('query-string');
const PRSIssueDetail = ({params, location}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [tabIndex, setTabIndex] = useState(0);
    const [page, setPage] = useState(1);
    const [isLoading, setLoading] = useState(false);
    const [formLoader, setFormLoader] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [currentFormDetail , setCurrentFormDetail] = useState(null);
    const [prsDetail, setPrsDetail] = useState({});
    const [prsInfo, setPrsInfo] = useState({list:[], pagination:{}})
    useEffect(() => {
        getPrsDetail(params.id);
        getCurrentFormDetail();
        getPRSListApi({page:page, per_page:50, ...location.query}, 'skeletonLoader');
    },[]);
    const getCurrentFormDetail = () => {
        setFormLoader(true);
         globalGetService(`technical/${params.type === 'engine' ? 'engine-workorder' :'workorder'}/${params.workOrderSlug}/forms/`,{form:params.formSlug})
         .then(response => {
            setFormLoader(false);
            if(checkApiStatus(response)){
                setCurrentFormDetail(response.data.data)
            }
        })
    }
    const getPrsDetail = (id, loaderType) => {
        setSkeletonLoader(true);
        globalGetService(`technical/workorder/${params.workOrderSlug}/gap-report/${id}/?asset_type=${params.type}`)
        .then(response => {
            if(checkApiStatus(response)){
                setPrsDetail(response.data.data);
            }
            setSkeletonLoader(false);
        })
    }
    const getPRSListApi = (query={}, loaderType) => {
        globalGetService(`technical/workorder/${params.workOrderSlug}/gap-report/?asset_type=${params.type}&sort=created_at&sort_by=desc`,removeEmptyKey(query))
        .then(response => {
            if(checkApiStatus(response)){
                setPrsInfo({...prsInfo, list:[ ...response.data.data.list], pagination:response.data.data.pagination});
            }
        })
    }
    const updateIssueUrl = (id) => {
        let location = browserHistory.getCurrentLocation()
        location = {
            ...location,
           
            pathname: `/${params.type}/${params.aircraft_slug}/technical/${params.workOrderSlug}/forms/${params.formSlug}/T011/${id}`
        }
        browserHistory.push(location);
        getPrsDetail(id,'skeletonLoader');
    }
    const onChangeFormStatus = (data) => {
        setLoading(true)
        globalPostService(`technical/workorder/${params.workOrderSlug}/form/${params.formSlug}/status-update/?asset_type=${params.type}`,data)
        .then(response => {
            setLoading(false)
            if(checkApiStatus(response)){
                getCurrentFormDetail('formLoader');
                enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            }else{
                getCurrentFormDetail('formLoader');
                enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            }
        });
    }
    return(
        <section className='project-forms-section'>
            { currentFormDetail && Object.keys(currentFormDetail).length ?
                <FormWrapper 
                    formInfo={currentFormDetail} 
                    updateFormStatusFn={(data) => onChangeFormStatus(data)}
                />:formLoader ? <FormSkeletonLoader />:null
            }
            <div className='prs-details-section'>
                <Paper id="scrollableDiv" className='prs-detail-list' style={{height: `${window.innerHeight-130}px`}}>
                    <InfiniteScroll
                        dataLength={prsInfo.pagination.total}
                        next={() => {setPage(page+1);getPRSListApi({page:page+1,per_page:50, ...location.query})}}
                        hasMore={prsInfo.list.length === prsInfo.pagination.total ? false:true}
                        loader={<h4 style={{textAlign:'center'}}>Loading...</h4>}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                              <b>Yay! You have seen it all</b>
                            </p>
                        }
                        scrollableTarget="scrollableDiv"
                    >
                        {prsInfo.list.map((item, index)=>
                            <QuickIssueList updateIssueUrl={()=>updateIssueUrl(item.id)} item={item}
                            
                            />
                        )}
                    </InfiniteScroll>
                </Paper>
                { skeletonLoader ? <PageLoader />:
                    <div className='prs-detail-content' style={{height: `${window.innerHeight-130}px`}}>
                        {prsDetail && Object.keys(prsDetail).length ?
                            <Fragment>
                                <div className='issue-title'>
                                    <IssueTitle 
                                     prsDetail={prsDetail}
                                     getResponseBack={()=>{  getPRSListApi({page:page, per_page:50, ...location.query}, 'skeletonLoader');getPrsDetail(params.id)}}
                                     currentFormDetail={currentFormDetail}
                                     getCurrentFormDetail={getCurrentFormDetail}
                                    />
                                </div>
                                <div className='issue-description'>
                                    <ContentExpansion valueKey='description' title={'Description'} content={prsDetail?.description||''}   currentFormDetail={currentFormDetail} getCurrentFormDetail={getCurrentFormDetail}/>
                                    <ContentExpansion valueKey='requirement_remarks' title={'Requirement Discrepancy'} prsDetail={prsDetail} content={prsDetail?.requirement_remarks||''}  currentFormDetail={currentFormDetail} getCurrentFormDetail={getCurrentFormDetail}/>
                                    <ContentExpansion valueKey='background_remarks' title={'Background Discrepancy'} prsDetail={prsDetail} content={prsDetail?.background_remarks||''}  currentFormDetail={currentFormDetail} getCurrentFormDetail={getCurrentFormDetail} />
                                </div>
                                <div className='issue-other-information'>
                                    <OtherInformation prsDetail={prsDetail} currentFormDetail={currentFormDetail}
                                    />
                                </div>
                                <Paper style={{marginBottom:'15px'}}>
                                    <Tabs value={tabIndex} onChange={(event, newValue)=> setTabIndex(newValue)}>
                                        <Tab label="Comment(s)" />
                                        <Tab label="Attachment(s)" />
                                    </Tabs>
                                </Paper>
                                {tabIndex === 0 ? <Comments  currentFormDetail={currentFormDetail} />:null}
                                {tabIndex === 1 ? <Attachment  currentFormDetail={currentFormDetail} getCurrentFormDetail={getCurrentFormDetail} />:null}
                            </Fragment>:null
                        }
                    </div>
                }
            </div>
            { isLoading ? <PageLoader />:null }
        </section>
    )
}
export default PRSIssueDetail;
import React, { Fragment, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import {arrayMoveImmutable} from 'array-move';
import { Grid, Button, Paper, Tooltip } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { FormSkeletonLoader, FormWrapper, ImageSlider, SortTechList, EditImageDialog } from '../../Elements';
import { UploadPictures, AddEditSection, SectionSorting, ImportTemplate } from '../components';
import { DeletePopUp, ExportManu, PageLoader } from '../../../shared_elements'
import { checkApiStatus, dataURItoBlob, downloadFileType, getLocalStorageInfo,onCheckFormPermission } from '../../../utils_v2';
import { globalGetService, globalPutService, globalPostService, globalDeleteService } from '../../../utils_v2/globalApiServices';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { globalStaticExportService } from '../../../globalServices';
import { trackActivity } from '../../../utils/mixpanel';
const GeneralPictures = ({params,files}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [circularloader,setCircularloader] = useState(false)
    const [formLoader, setFormLoader] = useState(true);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [currentFormDetail, setCurrentFormDetail] = useState(null);
    const [generalPictures, setGeneralPictures] = useState({list:[], pagination:{}});
    const [deletePicture, setDeletePicture] = useState({modal:false, sectionId:null, sectionTitle:'', picture:null});
    const [deleteSection, setDeleteSection] = useState({modal:false, section:{id:'',name:''}});
    const [sliderInfo, setSliderInfo] = useState({modal:false, title:'', images:[], startIndex:null});
    const [imgAnnotation, setImgAnnotation] = useState({modal:false, data:null, sectionId:null});
    const [uploadPictures, setUploadPictures] = useState({modal:false, mode:'', sectionId:null, sectionName:''});
    const [sectionConfig, setSectionConfig] = useState({modal:false, data:{}})
    useEffect(() => {
        getGeneralPictures({},'skeletonLoader');
        getCurrentFormDetail('formLoader');
        trackActivity('Project Management', {
            event_type: 'Page Visited',
            page_title: 'T001 Form'
        });
    },[]);
    const getCurrentFormDetail = (loaderType) => {
        if(loaderType){setFormLoader(true)}
        globalGetService(`technical/${params.type === 'engine' ? 'engine-workorder' :'workorder'}/${params.workOrderSlug}/forms/?asset_type=${params.type}`,{form:params.formSlug})
        .then(response => {
            if(loaderType){setFormLoader(false)}
            if(checkApiStatus(response)){
                setCurrentFormDetail(response.data.data)
            }
        })
    }
    const getGeneralPictures = (query={}, loaderType) => {
        if(loaderType === 'skeletonLoader'){setSkeletonLoader(true)}else{setLoading(true)}
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/general-pictures/`, {...query, type:'T001',asset_type:params.type})
        .then(response => {
            if(loaderType === 'skeletonLoader'){setSkeletonLoader(false)}else{setLoading(false)}
            if(checkApiStatus(response)){
                setGeneralPictures(response.data.data);
            }
        });
    }
    const onChangeFormStatus = (data) => {
        setLoading(true)
        globalPostService(`technical/workorder/${params.workOrderSlug}/form/${params.formSlug}/status-update/?asset_type=${params.type}`,data)
        .then(response => {
            setLoading(false)
            if(checkApiStatus(response)){
                getCurrentFormDetail('formLoader');
                enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                trackActivity('Project Management', {
                    event_type: 'Form Status Updated',
                    page_title: 'T001 Form',
                    form_slug: params.formSlug ? params.formSlug : '',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    status: data.status ? data.status: '',
                    request_body: data ? data : '',
                    success_msg: response.data.message
                  });
            }else{
                getCurrentFormDetail('formLoader');
                enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                trackActivity('Project Management', {
                    event_type: 'Form Status Update Failed',
                    page_title: 'T001 Form',
                    form_slug: params.formSlug ? params.formSlug : '',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    status: data.status ? data.status: '',
                    request_body: data ? data : '',
                    error_source: 'Backend',
                    error_msg: response.data.message
                  });
            }
        });
    }
    const exportFormReport = (extension) => {
        enqueueSnackbar('T001 Report Downloading ...', { variant: 'info',anchorOrigin:{horizontal:'right', vertical:'bottom'}})
        globalStaticExportService(`technical/v2/workorder/${params.workOrderSlug}/general-pictures/?asset_type=${params.type}`, {download:extension})
        .then(response => {
            downloadFileType(response, (`T001_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}.`), extension);
            closeSnackbar();
            trackActivity('Project Management', {
                event_type: 'File Exported',
                page_title: 'T001 Form',
                project_slug: params.project_slug ? params.project_slug : '',
                file_name: `T001_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`,
                file_extension: extension,
                success_msg: response.data.message
            })
        });
    }
    const onUploadGPictures = (files) => {
        let isLimit = true, section = generalPictures.list.find(section => section.id === uploadPictures.sectionId);
        isLimit = section.max_limit && section.max_limit !== '' ? (section.pictures.length+files.length) <= section.max_limit ? true:false:true;
        if(isLimit){
            for(let i = 0; i < files.length; i++) {
                enqueueSnackbar(`Uploading Files(${i+1}/${files.length})`, {autoHideDuration:30000, variant: 'info', anchorOrigin:{vertical: 'bottom',horizontal: 'right'}});
                let file = files[i];
                let formData = new FormData();    
                formData.append('aircraft_section_id', uploadPictures.sectionId);
                formData.append('section_name', uploadPictures.sectionName);
                formData.append('status', uploadPictures.status?uploadPictures.status:1); // status =1
                formData.append('form_type', 'T001');
                formData.append('files[]', file, file.name);

               setLoading(true)
                globalPostService(`technical/v2/workorder/${params.workOrderSlug}/general-pictures/?asset_type=${params.type}`, formData)
                .then(response => {
                    if(i+1 === files.length){setLoading(false);}
                    if(checkApiStatus(response)){
                        closeSnackbar();
                        getCurrentFormDetail()
                        setLoading(false)
                        enqueueSnackbar(`${file.name} uploaded successfully`, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                        trackActivity('Project Management', {
                            event_type: 'General Pictures Uploaded',
                            page_title: 'T001 Form',
                            workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                            aircraft_section_id: uploadPictures.sectionId ? uploadPictures.sectionId : '',
                            section_name: uploadPictures.sectionName ? uploadPictures.sectionName : '',
                            request_body: formData ? formData : '',
                            file_name: file.name ? file.name : '',
                            success_msg: `${file.name} uploaded successfully`
                          });
                        if(i+1 === files.length){
                            setUploadPictures({modal:false, mode:'', sectionId:null})
                            getGeneralPictures({});
                            getCurrentFormDetail()
                        }
                    }else{
                        enqueueSnackbar(`${file.name} uploaded fail`, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                        trackActivity('Project Management', {
                            event_type: 'General Pictures Upload Failed',
                            page_title: 'T001 Form',
                            workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                            aircraft_section_id: uploadPictures.sectionId ? uploadPictures.sectionId : '',
                            section_name: uploadPictures.sectionName ? uploadPictures.sectionName : '',
                            request_body: formData ? formData : '',
                            file_name: file.name ? file.name : '',
                            error_msg: `${file.name} upload failed`,
                            max_img_limit: isLimit ? isLimit : ''
                          });
                    }
                });
            }
        }else{
            enqueueSnackbar('Max Limit of image to section is reached', { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            trackActivity('Project Management', {
                event_type: 'General Pictures Upload Failed',
                page_title: 'T001 Form',
                workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                aircraft_section_id: uploadPictures.sectionId ? uploadPictures.sectionId : '',
                section_name: uploadPictures.sectionName ? uploadPictures.sectionName : '',
                error_msg: 'Max Limit of image to section is reached',
                max_img_limit: isLimit ? isLimit : ''
              });
        }
    }
    const annotateGenPicture = (file) => {
        setLoading(true);
        dataURItoBlob(file).then(editedImage => {
            let formData = new FormData();
            formData.append('file', editedImage);
            globalPutService(`technical/v2/workorder/${params.workOrderSlug}/general-pictures/${imgAnnotation.data.id}/?asset_type=${params.type}`, formData)
            .then(response => {
                setLoading(false);
                if(checkApiStatus(response)){
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    setGeneralPictures({
                        ...generalPictures,
                        list:generalPictures.list.map(section => section.id !== imgAnnotation.sectionId ? section : {
                            ...section,
                            pictures:section.pictures.map(picture => picture.id !== imgAnnotation.data.id ? picture : response.data.data)
                        })
                    });
                    trackActivity('Project Management', {
                        event_type: 'General Pictures Annotate Success',
                        page_title: 'T001 Form',
                        workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                        aircraft_section_id: uploadPictures.sectionId ? uploadPictures.sectionId : '',
                        section_name: uploadPictures.sectionName ? uploadPictures.sectionName : '',
                        request_body: formData ? formData : '',
                        img_annotate_id: imgAnnotation.data.id ? imgAnnotation.data.id : '',
                        success_msg: response.data.data
                      });
                    setImgAnnotation({modal:false, data:null, sectionId:null});
                }else{
                    enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }
            })
        });
    }
    const updateSortInspection = (oldIndex, newIndex, sectionId) => {
        let pictures = generalPictures.list.find(section => section.id === sectionId).pictures;
        setGeneralPictures({
            ...generalPictures,
            list:generalPictures.list.map((section, sectionIdex) => section.id !== sectionId ? section : {
                ...section,
                pictures:arrayMoveImmutable(section.pictures, oldIndex, newIndex)
            })
        });
        globalPostService(`technical/workorder/${params.workOrderSlug}/reorder-pictures/?asset_type=${params.type}`, {pictures:arrayMoveImmutable(pictures, oldIndex, newIndex).map(picture => picture.id)})
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                trackActivity('Project Management', {
                    event_type: 'Inspection Sorted',
                    page_title: 'T001 Form',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    aircraft_section_id: uploadPictures.sectionId ? uploadPictures.sectionId : '',
                    section_name: uploadPictures.sectionName ? uploadPictures.sectionName : '',
                    section_id: sectionId ? sectionId : '',
                    old_index: oldIndex ? oldIndex : '',
                    new_index: newIndex ? newIndex : '',
                    pictures: generalPictures && generalPictures.list.find(section => section.id === sectionId).pictures,
                    success_msg: response.data.data
                  });
            }else{
                enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            }
        });
    }
    const onDeleteGeneralPicture = () => {
       setDeletePicture({modal:false, picture:null, sectionTitle:'', sectionId:null});

       enqueueSnackbar(`Deleting file..`, {autoHideDuration:30000, variant: 'info', anchorOrigin:{vertical: 'bottom',horizontal: 'right'}});

       setCircularloader(true);
        globalDeleteService(`technical/workorder/${params.workOrderSlug}/delete-pictures/?asset_type=${params.type}`, {aircraft_section_id:deletePicture.sectionId, pictures:[deletePicture.picture.id]})
        .then(response => {
            setCircularloader(false);
            if(checkApiStatus(response)){
                closeSnackbar();
                enqueueSnackbar("Picture deleted Successfully", { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                setGeneralPictures({
                    ...generalPictures,
                    list:generalPictures.list.map(section => section.id !== deletePicture.sectionId ? section : {
                        ...section,
                        pictures:section.pictures.filter(picture => picture.id !== deletePicture.picture.id)
                    })
                });
                getCurrentFormDetail()
                trackActivity('Project Management', {
                    event_type: 'General Pictures Deleted',
                    page_title: 'T001 Form',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    aircraft_section_id: deletePicture.sectionId ? deletePicture.sectionId : '',
                    section_name: uploadPictures.sectionName ? uploadPictures.sectionName : '',
                    pictures:[deletePicture.picture.id],
                    success_msg: response.data.data
                  });

            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        });
    }
    const onDeleteSection = () => {
        setCircularloader(true)
        globalDeleteService(`technical/v2/workorder/${params.workOrderSlug}/sections/?type=T001&asset_type=${params.type}`, {id:deleteSection.section.id,asset_type:params.type})
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                setGeneralPictures({
                    ...generalPictures,
                    list:generalPictures.list.filter(section => section.id !== deleteSection.section.id)
                });
                getCurrentFormDetail()
                setCircularloader(false)
                trackActivity('Project Management', {
                    event_type: 'Section Deleted',
                    page_title: 'T001 Form',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    aircraft_section_id: deletePicture.sectionId ? deletePicture.sectionId : '',
                    section_id: deleteSection.section.id ? deleteSection.section.id : '',
                    success_msg: response.data.data
                  });
                setDeleteSection({modal:false, section:{id:'',name:''}});
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        });
    }
    const updateSlider = (pictures, title, index) => {
        setSliderInfo({modal:true, title: title, startIndex:index, images:pictures.map(item => {return {original:item.image, thumbnail:item.image}}),})
    }
    return(
        <section className='project-forms-section'>
            { currentFormDetail && Object.keys(currentFormDetail).length ?
                <FormWrapper 
                    hideAction={currentFormDetail?.current_form.progress === 0 ? true:false}
                    formInfo={currentFormDetail} 
                    exportReportBtn={<ExportManu disabled={generalPictures?.list?.length ? false: true} exportReportFn={(file) => exportFormReport(file.extension)} title="Export Report" files={[{title:'PDF', extension: 'pdf', key:''},{title:'Doc', extension: 'docx', key:''}]} />}
                    updateFormStatusFn={(data) => onChangeFormStatus(data)}
                />:formLoader ? <FormSkeletonLoader />:null
            }
            { !skeletonLoader && generalPictures.list.length === 0 ? null:
                <div className='t001-header' >
                    <Grid container spacing={1} >
                        <Grid item md={6} xs={12} style={{display:'none'}}>
                            <ul className='list-inline form-left-cta'>
                                <li className='list-inline-item'>
                                    <Button onClick={() => {setUploadPictures({modal:true, mode:'folder'}); trackActivity('Project Management', {page_title: 'T001 Form', event_type:'Clicked on Upload As Folder Button'})}} color='primary' variant='contained' size='small'>Upload As Folder</Button>
                                </li>
                                <li className='list-inline-item'>
                                    <Button color='primary' variant='outlined' size='small' onClick={()=> trackActivity('Project Management', {page_title: 'T001 Form', event_type:'Clicked on Download Empty Structure Button'})} >Download Empty Structure</Button>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item md={12} xs={12}>
                        { onCheckFormPermission(currentFormDetail)  ?
                                <ul className='list-inline flex-centered form-right-cta'>
                                    <li className='list-inline-item'>
                                        <Button onClick={() => {setSectionConfig({modal:true, data:{name:'', description:'', max_limit:''}}); trackActivity('Project Management', {page_title: 'T001 Form', event_type:'Clicked on Add New Section Button'})}} color='primary' variant='contained' size='small'>Add New Section</Button>
                                    </li>
                                   { generalPictures.list.length !== 0 ?
                                    <li className='list-inline-item'>
                                        <SectionSorting 
                                            getResponseBack={()=> getGeneralPictures({})}
                                        />
                                    </li>:null}
                                </ul>:null
                            }
                        </Grid>
                    </Grid>
                </div>
             }
            {skeletonLoader ? [1].map(item => <Skeleton style={{marginBottom:'2px'}} variant="rect" width={'100%'} height={400} />):
                <Paper className='t001-general-pictures'>
                    <div className='section-content-blk' style={{minHeight: (window.innerHeight - 200) + 'px', marginBottom:'0px', paddingBottom:'0px'}}>
                        {generalPictures.list.map((section, sectionIndex) => 
                            <Grid container spacing={1} key={sectionIndex}>
                                <Grid item md={12} xs={12} style={{minHeight: '200px'}}>
                                    <div className='pictures-row'>
                                        <div className="section-title flex-centered">
                                            <div className='section-info'>
                                                <h3>{section.name}</h3>    
                                                {section.description ? <p>{section.description}</p>:null}
                                            </div>
                                            { onCheckFormPermission(currentFormDetail)  ?
                                                <ul className='list-inline flex-centered'>
                                                    <li className='list-inline-item'>
                                                        <Tooltip title='Edit Section' arrow><EditIcon onClick={() => setSectionConfig({modal:true, data:{id: section.id,name:section.name, description:section.description, max_limit:section.max_limit, pictures:section?.pictures}})} color='primary' fontSize='small' /></Tooltip>
                                                    </li>
                                                    <li className='list-inline-item'>
                                                        <Tooltip title='Delete Section' arrow><DeleteOutlineIcon onClick={() => setDeleteSection({modal:true, section:{id: section.id, name:section.name}})} color='secondary' fontSize='small' /></Tooltip>
                                                    </li>
                                                    { section.max_limit ? 
                                                        section.pictures.length < section.max_limit ?
                                                        <li className='list-inline-item'>
                                                            <Button onClick={() => setUploadPictures({modal:true, mode:'file', sectionId:section.id, sectionName:section.name})} color='primary' size='small' variant='outlined'>Upload</Button>
                                                        </li>:null:
                                                        <li className='list-inline-item'>
                                                            <Button onClick={() => setUploadPictures({modal:true, mode:'file', sectionId:section.id, sectionName:section.name})} color='primary' size='small' variant='outlined'>Upload</Button>
                                                        </li>
                                                    }
                                                </ul>:null
                                            }
                                        </div>
                                        <div className="sort-image-cn">
                                            {section.pictures.length ?
                                                <SortTechList
                                                    formInfo={currentFormDetail}
                                                    items={section.pictures}
                                                    onSortEnd={(dataIndex) => updateSortInspection(dataIndex.oldIndex, dataIndex.newIndex, section.id)}
                                                    updateSlider={(index) => updateSlider(section.pictures, section.name, index)}
                                                    onRemoveInspection={(picture) => setDeletePicture({modal:true, sectionId:section.id, sectionTitle:section.name, picture:picture})}
                                                    onEditInspection={(picture) => setImgAnnotation({modal:true, data:picture, sectionId:section.id})}
                                                    pageTitle='T001 Image Sort'
                                                />:
                                                <div style={{textAlign:'center', padding:'50px 0'}}>No Picture(s) uploaded yet</div>
                                            }
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        )}
                        { !skeletonLoader && generalPictures.list.length === 0 ?
                            <ImportTemplate 
                                getResponseBack={() => getGeneralPictures({})}
                            />:null
                        }
                    </div>
                </Paper>
            }
            { deletePicture.modal ?
                <DeletePopUp
                    modal={deletePicture.modal}
                    toggleModalFn={() => setDeletePicture({modal:false, sectionId:null, sectionTitle:'', picture:null})}
                    title={`Delete ${deletePicture.sectionTitle}'s Picture`}
                    content={<h4>Are you sure you want to Delete?</h4>}
                    deleteRecordFn={() => onDeleteGeneralPicture()}
                />:null
            }
            { deleteSection.modal ?
                <DeletePopUp
                    modal={deleteSection.modal}
                    toggleModalFn={() => setDeleteSection({modal:false, section:{id:'',name:''}})}
                    title={`Delete ${deleteSection.section.name}`}
                    content={<h4>Are you sure you want to Delete?</h4>}
                    deleteRecordFn={() => onDeleteSection()}
                    circularloader={circularloader}
                />:null
            }
            { sliderInfo.modal ?
                <ImageSlider
                    sliderInfo={sliderInfo}
                    toggleModalFn={() => setSliderInfo({modal:false, title:'', startIndex:null, images:[]})}
                />:null
            }
            { imgAnnotation.modal ?
                <EditImageDialog
                    imgAnnotation={imgAnnotation}
                    saveAnnotateImg={(file) => annotateGenPicture(file)}
                    toggleModalFn={() => setImgAnnotation({modal:false, data:null, sectionId:null})}
                />:null
            }
            { uploadPictures.modal ?
                <UploadPictures 
                    generalPictures={generalPictures}
                    onUpload={(files) => onUploadGPictures(files)}
                    uploadPictures={uploadPictures} 
                    toggleModalFn={() => setUploadPictures({modal:false, mode:'', sectionId:null, sectionName:''})} />:null
            }
            { sectionConfig.modal ? 
                <AddEditSection 
                    sectionConfig={sectionConfig}
                    toggleModalFn={() =>setSectionConfig({modal:false,data:{}})}
                    getResponseBack={() => getGeneralPictures({})}
                />:null
            }
            {isLoading ? <PageLoader />:null}
        </section>
    )
}
export default GeneralPictures;
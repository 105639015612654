import React, { useState, useEffect } from 'react';
import { Link, browserHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Paper, Button, Table, TableBody, TableCell, TableHead, TableRow, TextField, Grid, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { STableLoader } from '../../../shared_elements/loaders';
import { PageLoader } from '../../../shared_elements';
import Rating from '@material-ui/lab/Rating';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { globalGetService, globalPostService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus, localTimeFn, getLocalStorageInfo } from '../../../utils_v2';
import { fieldDateFormat, backendDateFormat } from '../../../constants'
const OprForm = ({params}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false)
    const [oprInfo, setOprInfo] = useState(null);
    const [project, setProject] = useState(null);
    const [error, setError] = useState({});
    const [zeroRating, setZeroRating] = useState({modal:false});
    useEffect(() => {
        getOprDetail('skeletonLoader');
        getProjectDetail();
    },[]);
    const sumOfRating = (opr) => {
        let sum = 0;
        opr.questions.map(item => {
            sum += item?.answers?.rating||0;
        });
        return sum;
    }
    const getProjectDetail = () => {
        globalGetService(`technical/project/${params.project_slug}/`)
        .then(response => {
            if(checkApiStatus(response)){
                setProject(response.data.data);
            }
        }) 
    }
    const getOprDetail = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`technical/project/${params.project_slug}/opr/`)
        .then(response => {
            if(checkApiStatus(response)){
                setOprInfo(response.data.data)
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        })
    }
    const onSubmitOpr = (data) => {
        let validationInputs = {
            date_of_inspection: data?.date_of_inspection ? '':'Please enter Date of Inspection',
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            if(data.status.label === 'Rejected'){
                let rejections = [];
                data.questions.map(item => {
                    if(item.answers.rejection_reason){
                        rejections.push(item.answers.rejection_reason)
                    };
                });
                if(rejections.length==0){
                    enqueueSnackbar('Please enter rejection reason for specific Question(s)', { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    return;
                }
            }
            if(data.status.label === 'Submitted'){
                if(sumOfRating(data) === 0){
                    setZeroRating({modal:true})
                    return;
                }
            }
            onSubmitOprFrom(data);
        }else{
            setError(validationInputs)
        }
    }
    const onSubmitOprFrom = (data) => {
        setLoading(true);
        let payload = Object.assign({}, data);
        delete payload.updated_at
        payload = {
            ...payload,
            status:data.status.value
        };
        globalPostService(`technical/project/${params.project_slug}/opr/save/`, payload)
        .then(response => {
            setLoading(false)
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                getOprDetail();
                setZeroRating({modal:false})
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        })
    }
    const onFieldChange = (keyParam, value, index) => {
        setOprInfo({...oprInfo, questions:oprInfo.questions.map((item, itemIndex) => itemIndex !== index ? item: {...item, answers: {rating:item?.answers?.rating||0, remark:item?.answers?.remark||null, rejection_reason:item?.answers?.rejection_reason||null, [keyParam]:value}})})
    }
    let userIds = [];
    if(project && Object.keys(project).length){
        userIds = [...project?.leads.map(lead => lead.id)];
        if(project?.opr_user?.id){
            userIds = [...userIds, project?.opr_user?.id]; 
        }
    }
    console.log('project',project);
    console.log('oprInfo?.status?.label',oprInfo?.status?.label);
    return(
        <section className='projects-management-apps projects-management-section'>
            {(() => {
                if(project && Object.keys(project).length){
                    let oprFlg = project && Object.keys(project).length && project.opr_required;
                    let oprAccess = project.leads.map((lead) => lead.id);
                    let leads = project.leads.map((lead) => lead.id);
                    if(project.opr_user){
                        oprAccess = [...oprAccess, project.opr_user.id]
                    }
                    if(oprFlg && oprAccess.includes(getLocalStorageInfo().user.id)){
                        return(
                            <>
                                <Paper style={{marginBottom:'10px'}}>
                                    <div style={{padding:'5px 10px', background:'#e7e7e7'}}> 
                                        <Grid container spacing={1}>
                                            <Grid item md={6}>
                                                <Link className='flex-centered' to={`/technical/project/view/${params.project_slug}`}>
                                                    <ArrowBackIcon color='primary' fontSize='small' /> 
                                                    <span>OPR Detail</span>
                                                </Link>
                                            </Grid>
                                            <Grid item md={6}>
                                                <ul className='list-inline' style={{float:'right'}}>
                                                    {oprInfo?.status?.label === 'Approved' && leads.includes(getLocalStorageInfo().user.id) ?
                                                        <li className="list-inline-item">
                                                            <Button onClick={() => onSubmitOpr({...oprInfo, status:{value:1, label:'Draft'}})} color='secondary' size='small' variant='contained'>Revert to Draft</Button>
                                                        </li>:null
                                                    }
                                                    {oprInfo?.status?.label === 'Submitted' && leads.includes(getLocalStorageInfo().user.id) ?
                                                        <>
                                                            <li className="list-inline-item">
                                                                <Button onClick={() => onSubmitOpr({...oprInfo, status:{value:3, label:'Rejected'}})} color='secondary' size='small' variant='contained'>Reject</Button>
                                                            </li>
                                                            <li className="list-inline-item">
                                                                <Button onClick={() => onSubmitOpr({...oprInfo, status:{value:4, label:'Approved'}})} color='primary' size='small' variant='contained'>Approve</Button>
                                                            </li>
                                                        </>:null
                                                    }
                                                    { (oprInfo?.status?.label === 'Draft' || oprInfo?.status?.label === 'Rejected') && project?.opr_user?.id === getLocalStorageInfo().user.id ?
                                                        <>
                                                            <li className="list-inline-item">
                                                                <Button onClick={()=>onSubmitOpr({...oprInfo, status:{value:2, label:'Submitted'}})} color='primary' size='small' variant='outlined'>Submit</Button>
                                                            </li>
                                                            <li className="list-inline-item">
                                                                <Button onClick={() => onSubmitOpr(oprInfo)} color='primary' size='small' variant='contained'>Save</Button>
                                                            </li>
                                                        </>
                                                        :null
                                                    }
                                                </ul>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div style={{padding:'5px 10px'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} md={4}>
                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                    <KeyboardDatePicker
                                                        margin="normal"
                                                        required
                                                        disabled={(['Draft', 'Rejected', 'Submitted', 'Approved'].includes(oprInfo?.status?.label||'') && project?.opr_user?.id !== getLocalStorageInfo().user.id) || (['Submitted', 'Approved'].includes(oprInfo?.status?.label||'') && project?.opr_user?.id === getLocalStorageInfo().user.id) ? true : false}
                                                        id="date_of_inspection"
                                                        label="Date of Inspection"
                                                        format={fieldDateFormat}
                                                        fullWidth
                                                        InputLabelProps={{shrink: true}}
                                                        error={error.date_of_inspection ? true:false}
                                                        helperText={error.date_of_inspection ? error.date_of_inspection:''}
                                                        value={oprInfo?.date_of_inspection||null}
                                                        onChange={(data, value) => {setOprInfo({...oprInfo, 'date_of_inspection': data ? moment(data).format(backendDateFormat):data}); setError({...error, date_of_inspection:''})}}
                                                        inputVariant='outlined'
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    disabled
                                                    id="last_modified_at"
                                                    fullWidth
                                                    label='Last Modified @'
                                                    margin="normal"
                                                    value={oprInfo?.updated_at ? localTimeFn(oprInfo?.updated_at):''}
                                                    InputLabelProps={{shrink: true}}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    disabled
                                                    id="status"
                                                    fullWidth
                                                    label='Status'
                                                    margin="normal"
                                                    value={oprInfo?.status?.label||''}
                                                    InputLabelProps={{shrink: true}}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12} style={{display:'none'}}>
                                                <TextField
                                                    disabled
                                                    id="last_modified_by"
                                                    fullWidth
                                                    label='Rejection Reason'
                                                    margin="normal"
                                                    value={'Vijay Kumar'}
                                                    InputLabelProps={{shrink: true}}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                    
                                </Paper>
                                {skeletonLoader ? <STableLoader count={4} />:
                                    <Paper>
                                        <Table className='mui-table-format'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Sr. No.</TableCell>
                                                    <TableCell>Question(s)</TableCell>
                                                    <TableCell>Score({sumOfRating(oprInfo)}/100)</TableCell>
                                                    <TableCell>Remarks</TableCell>
                                                    { oprInfo?.status?.label === 'Rejected' || oprInfo?.status?.label === 'Submitted' ?
                                                        <TableCell>Reason</TableCell>:null
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { oprInfo && oprInfo.questions && oprInfo.questions.map((item, index) =>
                                                    <TableRow>
                                                        <TableCell style={{width:'70px'}}>{index+1}</TableCell>
                                                        <TableCell>{item.question}</TableCell>
                                                        <TableCell style={{width:'100px'}}>
                                                            <Rating
                                                                disabled={(['Draft', 'Rejected', 'Submitted', 'Approved'].includes(oprInfo?.status?.label||'') && project?.opr_user?.id !== getLocalStorageInfo().user.id) || (['Submitted', 'Approved'].includes(oprInfo?.status?.label||'') && project?.opr_user?.id === getLocalStorageInfo().user.id) ? true : false}
                                                                max={4}
                                                                name={`rating_${index}`}
                                                                value={item?.answers?.rating||0}
                                                                onChange={(event, newValue) => onFieldChange('rating', newValue, index)}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                disabled={(['Draft', 'Rejected', 'Submitted', 'Approved'].includes(oprInfo?.status?.label||'') && project?.opr_user?.id !== getLocalStorageInfo().user.id) || (['Submitted', 'Approved'].includes(oprInfo?.status?.label||'') && project?.opr_user?.id === getLocalStorageInfo().user.id) ? true :false}
                                                                id="remark"
                                                                fullWidth
                                                                margin="none"
                                                                value={item?.answers?.remark||''}
                                                                InputLabelProps={{shrink: true}}
                                                                onChange={(e) => onFieldChange('remark', e.target.value, index)}
                                                                variant='outlined'
                                                            />
                                                        </TableCell>
                                                        { oprInfo?.status?.label === 'Rejected' || oprInfo?.status?.label === 'Submitted' ?
                                                            <TableCell>
                                                                { oprInfo?.status?.label === 'Submitted' && project.opr_user.id === getLocalStorageInfo().user.id ? 'NA':
                                                                    <TextField
                                                                        disabled={(['Rejected'].includes(oprInfo?.status?.label||'') && project?.opr_user?.id !== getLocalStorageInfo().user.id) ? true : false}
                                                                        id="rejection_reason"
                                                                        fullWidth
                                                                        margin="none"
                                                                        value={item?.answers?.rejection_reason||''}
                                                                        InputLabelProps={{shrink: true}}
                                                                        onChange={(e) => onFieldChange('rejection_reason', e.target.value, index)}
                                                                        variant='outlined'
                                                                    />
                                                                }
                                                            </TableCell>:null
                                                        }
                                                        
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                }
                            </>
                        )
                    }else{
                        return(
                            <div style={{textAlign:'center'}}>
                                <h4>You don't have access to this page</h4>
                                <p onClick={() => browserHistory.push(`/technical/project/view/${params.project_slug}/`)} style={{color:'#043BFC', textDecoration: 'underline', cursor: 'pointer'}}>Go Back</p>
                            </div>
                        )
                    }
                }
            })()}
            { zeroRating.modal ?
                <Dialog
                    maxWidth="md"
                    open={zeroRating.modal}
                    onClose={() => setZeroRating({modal:false})}
                    className='project-management-modal'
                >
                    <DialogTitle>Submit OPR Form</DialogTitle>
                    <DialogContent dividers={true}>
                        <div style={{textAlign:'center'}}>
                            <h5 style={{fontSize:'15px', marginBottom:'16px'}}>Are you sure you want to submit with 0 Rating</h5>
                            <ul className='list-inline'>
                                <li className='list-inline-item'>
                                    <Button onClick={() => setZeroRating({modal:false})} color='primary' size='small' variant='outlined'>Cancel</Button>
                                </li>
                                <li className='list-inline-item'>
                                    <Button onClick={() => onSubmitOprFrom({...oprInfo, status:{value:2, label:'Submitted'}})} color='primary' size='small' variant='contained'>Submit</Button>
                                </li>
                            </ul>
                        </div>
                    </DialogContent>
                </Dialog>:null
            }
            { isLoading ? <PageLoader />:null }
        </section>
    )
}
export default OprForm;
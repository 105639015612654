import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Tabs, Tab, Button, Paper } from '@material-ui/core';
import {arrayMoveImmutable} from 'array-move';
import { DeletePopUp, PageLoader, ExportManu, EmptyCollection } from '../../../shared_elements';
import { ComponentInspected, InspectionPictures, GeneralDetail, UploadPictures } from '../components';
import { FormWrapper, EditImageDialog, ImageSlider, FormSkeletonLoader } from '../../Elements';
import { checkApiStatus, dataURItoBlob, downloadFileType, getLocalStorageInfo } from '../../../utils_v2';
import { globalGetService, globalPutService, globalPostService, globalDeleteService } from '../../../utils_v2/globalApiServices';
import { globalStaticExportService } from '../../../globalServices';
import { trackActivity } from '../../../utils/mixpanel';
const BoreScopeInspection = ({params}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [formLoader, setFormLoader] = useState(true);
    const [currentFormDetail, setCurrentFormDetail] = useState({});
    const [tabIndex, setTabIndex] = useState('general-details');
    const [inspectionPictures, setInspectionPictures]  = useState({list:[], pagination:null});
    const [deletePicture, setDeletePicture] = useState({modal:false, picture:null});
    const [sliderInfo, setSliderInfo] = useState({modal:false, title:'', images:[], startIndex:null});
    const [imgAnnotation, setImgAnnotation] = useState({modal:false, data:null});
    const [uploadModal, setUploadModal] = useState(false);
    useEffect(() => {
        getCurrentFormDetail();
        trackActivity('Project Management', { event_type: 'Page Visited', page_title: 'TE001 Form' });
    },[]);
    const getCurrentFormDetail = (loaderType) => {
        if(loaderType){setFormLoader(true)}
        globalGetService(`technical/engine-workorder/${params.workOrderSlug}/forms/`,{form:params.formSlug})
        .then(response => {
            if(checkApiStatus(response)){
                setCurrentFormDetail(response.data.data)
            }
            if(loaderType){setFormLoader(false)}
        })
    }
    const getInspectionPictures = () => {
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/borescope-pictures/`)
        .then(response => {
            if(checkApiStatus(response)){
                setInspectionPictures(response?.data?.data);
            }   
        })
    }

    const onChangeFormStatus = (data) => {
        setLoading(true)
        globalPostService(`technical/workorder/${params.workOrderSlug}/form/${params.formSlug}/status-update/`,data)
        .then(response => {
            setLoading(false)
            if(checkApiStatus(response)){
                getCurrentFormDetail('formLoader');
                enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            }
        });
    }
    const exportFormReport = (extension) => {
        enqueueSnackbar('TE001 Report Downloading ...', {autoHideDuration:50000, variant: 'info',anchorOrigin:{horizontal:'right', vertical:'bottom'}})
        globalStaticExportService(`technical/engine-workorder/${params.workOrderSlug}/borescope-details/`, {download:extension})
        .then(response => {
            downloadFileType(response, (`TE001_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}.`), extension);
            closeSnackbar();
        });
    }
    const onUploadInspectionPictures = (files) => {
        let formData = new FormData();
        formData.append('borescope', true);
        for(let i = 0; i < files.length; i++) {
            let file = files[i];
            formData.append('files', file, file.name);
        }
        setLoading(true)
        globalPostService(`technical/v2/workorder/${params.workOrderSlug}/borescope-file-upload/`, formData)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                getInspectionPictures();
                getCurrentFormDetail('pageLoader')
            }
            setLoading(false)
        })
    }
    const onEditPictureDescription = (value, id) => {
        let isSave = false;
        const picture = inspectionPictures.list.find((item) => item.id === id);
        if(value?.trim()?.length){
            if(value !== picture.description){
                isSave = true;
            }
        }else{
            if(picture?.description &&  picture?.description?.trim()?.length){
                isSave = true;
            }
        }
        if(isSave){
            globalPutService(`technical/v2/workorder/${params.workOrderSlug}/borescope-pictures/`, {id:id, description:value})
            .then(response => {
                if(checkApiStatus(response)){
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    setInspectionPictures({...inspectionPictures, list: inspectionPictures.list.map((item) => item.id !== picture.id ?item : {...item, description:value})})
                }
            })
        }
    }
    const onDeletePicture = () => {
        globalDeleteService(`technical/v2/workorder/${params.workOrderSlug}/borescope-pictures/${deletePicture.picture.id}/`)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                setDeletePicture({modal:false, picture:null});
                getInspectionPictures();
                getCurrentFormDetail('pageLoader');
            }
        })
    }
    const updateSlider = (pictures, title, index) => {
        setSliderInfo({modal:true, title: 'Inspection Picture(s)', startIndex:index, images:pictures.map(item => {return {original:item.image, thumbnail:item.image}}),})
    }
    const annotateGenPicture = (file) => {
        setLoading(true);
        dataURItoBlob(file).then(editedImage => {
            let formData = new FormData();
            formData.append('file', editedImage);
            globalPostService(`technical/v2/workorder/${params.workOrderSlug}/borescope-pictures-edit/${imgAnnotation.data.id}/`, formData)
            .then(response => {
                setLoading(false);
                if(checkApiStatus(response)){
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    setImgAnnotation({modal:false, data:null, sectionId:null});
                    getInspectionPictures();
                }else{
                    // enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }
            })
        });
    }
    const updateSortInspection = (oldIndex, newIndex) => {
        const pictures = arrayMoveImmutable(inspectionPictures.list, oldIndex, newIndex)
        setInspectionPictures({list:pictures});
        globalPostService(`technical/v2/workorder/${params.workOrderSlug}/borescope-pictures-reorder/`, {pictures:pictures.map(picture => picture.id)})
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            }else{
                // enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            }
        });
    }
    return(
        <section className='projects-management-apps project-forms-section'>
            { currentFormDetail && Object.keys(currentFormDetail).length ?
                <FormWrapper 
                    hideAction={currentFormDetail?.current_form.progress === 0 ? true:false}
                    formInfo={currentFormDetail} 
                    exportReportBtn={<ExportManu disabled={currentFormDetail?.current_form.progress === 0 ? true:false} exportReportFn={(file) => exportFormReport(file.extension)} title="Export Report" files={[{title:'PDF', extension: 'pdf', key:''}]} />}
                    updateFormStatusFn={(data) => onChangeFormStatus(data)}
                />:formLoader ? <FormSkeletonLoader />:null
            }
            <div className='technical-engine-forms'>
                <Paper square style={{marginBottom:'15px'}}>
                    <Tabs
                        value={tabIndex}
                        onChange={(e, newValue) => {setTabIndex(newValue); newValue === 'inspection-pictures'? getInspectionPictures():e.preventDefault()}}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                    >
                        <Tab label="General Details" value='general-details' />
                        <Tab label="Components Inspected" value='components-inspected' />
                        <Tab label="Inspection Picture(s)" value='inspection-pictures' />
                    </Tabs>
                </Paper>
                { tabIndex === 'general-details' ?
                    <GeneralDetail currentFormDetail={currentFormDetail} getCurrentFormDetail={getCurrentFormDetail}/>:null
                }
                { tabIndex === 'components-inspected' ?
                    <ComponentInspected currentFormDetail={currentFormDetail} getCurrentFormDetail={getCurrentFormDetail} />:null
                }
                { tabIndex === 'inspection-pictures' ?
                    <Paper className='te001-inspection-pictures'>
                        <div style={{textAlign:'right'}}>
                            { currentFormDetail?.current_form?.permissions?.can_save ?
                                <Button onClick={()=> setUploadModal(true)} size='small' color='primary' variant='contained'>Upload Inspection Picture(s)</Button>:null
                            }
                        </div>
                        
                        { inspectionPictures?.list?.length ?
                            <div className='pictures-blk'>
                                <InspectionPictures 
                                    formInfo={currentFormDetail} 
                                    onRemoveInspection={(picture) => setDeletePicture({modal:true, picture:picture})}
                                    onEditInspection={(picture) => setImgAnnotation({modal:true, data:picture})}
                                    updateSlider={(index) => updateSlider(inspectionPictures.list, '', index)}
                                    onSortEnd={(dataIndex) => updateSortInspection(dataIndex.oldIndex, dataIndex.newIndex)}
                                    items={inspectionPictures?.list||[]}
                                    onComponentChange={onEditPictureDescription}
                                />
                            </div>:null
                        }
                        { !inspectionPictures?.list?.length ? <EmptyCollection title='No records found' />:null }
                    </Paper>
                    :null
                }
            </div>
            
            { sliderInfo.modal ?
                <ImageSlider
                    sliderInfo={sliderInfo}
                    toggleModalFn={() => setSliderInfo({modal:false, title:'', startIndex:null, images:[]})}
                />:null
            }
            { imgAnnotation.modal ?
                <EditImageDialog
                    title='Edit Inspection Picture'
                    imgAnnotation={imgAnnotation}
                    saveAnnotateImg={(file) => annotateGenPicture(file)}
                    toggleModalFn={() => setImgAnnotation({modal:false, data:null, sectionId:null})}
                />:null
            }
            { deletePicture.modal ?
                <DeletePopUp
                    modal={deletePicture.modal}
                    toggleModalFn={() => setDeletePicture({modal:false, picture:null})}
                    title="Delete Inspection Picture"
                    content={<h4>Are you sure you want to Delete?</h4>}
                    deleteRecordFn={() => onDeletePicture()}
                />:null
            }
            { uploadModal ? 
                <UploadPictures 
                    uploadModal={uploadModal}
                    onUpload={onUploadInspectionPictures}
                    toggleModalFn={() => setUploadModal(false)}
                /> :null 
            }
            { isLoading ? <PageLoader />:null }
        </section>
    )
}
export default BoreScopeInspection;
import React from 'react';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { getLocalStorageInfo } from '../../../utils';
class MultiDropDown extends React.Component {
  constructor(props){
    super(props);
    this.state ={
      value: ''
    }
  }
  componentDidMount(){
    this.setState({
      value: this.props.value
    })
  }
  // componentWillReceiveProps(nextProps){
  //   if(this.props.value != nextProps.value){
  //     this.setState({
  //       value: nextProps.value
  //     })
  //   }
  // }
  onChange = (selectdVal) => {
    let array = [];
    selectdVal.map(value => {
        array.push(value[this.props.valueKey]);
      });
    // this.setState(prevState => ({
    //   ...prevState,
    //   error: '',
    //   value: array
    // }));
    this.props.updateForm(array);

  }
  // onBlur = () => {
  //   this.props.updateForm(this.state.value);
  // }
  render(){
    let userInfo= getLocalStorageInfo().user
    const { type, isDisabled } = this.props;
    if(this.state.type == ''){
      return null
    }
    return(
        <div className={`tech-sum-item  ${this.props.className}`}>
          {
            this.props.children ?
            <label className="label">
              {this.props.children}
              {
                this.props.required ? <sup className="reqStar"> * </sup> :null
              }
            </label>
            :null
          }
          {type == 'View' ?
            <h6>{this.props.value}</h6> :
          <Select
            name="form-field-name"
            placeholder={this.props.placeholder ? this.props.placeholder : ''}
            className="custom-select-block"
            value={this.props.value}
            options = {this.props.options}
            labelKey={this.props.labelKey}
            valueKey={this.props.valueKey}
            disabled = {isDisabled? isDisabled : userInfo.permission['technical']['projects'].indexOf('C') != '-1'?false: true }
            multi= {true}
            onBlur={this.onBlur}
            onChange={(target) => this.onChange(target)}
          />}
          {this.props.error != '' ? <h6 className="error-msg">{this.props.error}</h6> : null}
        </div>
    )
  }
}
export default MultiDropDown;

import React from 'react';
import { browserHistory, withRouter } from 'react-router';
import { Tabs, Tab, Paper, Tooltip } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { trackActivity } from '../../../utils/mixpanel';
import { getLocalStorageInfo, onCheckPermission } from '../../../utils_v2';
import config from '../../../config'
import { InfoIcon, ToolTipHover } from '../../../shared';
const ProjectDetailTab = ({ params, tabIndex, handleTabChange }) => {
  const onChangeRoute = (event, newValue) => {
    switch (newValue) {
      case 'inspection':
        browserHistory.push(`/technical/project/view/${params.project_slug}`);
        break;
      case 'budget':
        if(onCheckPermission('expense_management','expense','R')){
          browserHistory.push(`/technical/project/${params.project_slug}/budget/expense`);
        }else if(onCheckPermission('expense_management','budget_allocation','R')){
          browserHistory.push(`/technical/project/${params.project_slug}/budget/allocation`);
        }else{
          browserHistory.push(`/technical/project/${params.project_slug}/budget/analytics`);
        }
        break;
      case 'task-management':
        if(onCheckPermission('task_management','tasks','R')){
          browserHistory.push(`/technical/project/${params.project_slug}/tasks`);
        }else if(onCheckPermission('task_management','milestone','R')){
          browserHistory.push(`/technical/project/${params.project_slug}/milestones`);
        }else if(onCheckPermission('task_management','issues','R')){
          browserHistory.push(`/technical/project/${params.project_slug}/issues`);
        }else{
          browserHistory.push(`/technical/project/${params.project_slug}/stages`);
        }
        
        break;
      case 'project-users':
        browserHistory.push(`/technical/project/${params.project_slug}/project-users`);
        break;
      default:
        browserHistory.push('/technical/projects?project_status=all');
        break;
    }
  }
  return (
    <Paper className='project-tabs' style={{ margin: '-15px -15px 10px -15px' }}>
      <Tabs
        value={tabIndex}
        onChange={onChangeRoute}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label={<span>Inspection</span>} value="inspection" onClick={() => trackActivity('Project Detail', { event_type: 'Tab Clicked', event_desc: 'Clicked on Inspection Tab' })} />
        { getLocalStorageInfo()?.user?.permission?.expense_management ?
          <Tab label={<span>Expense Management </span>} value="budget" onClick={() => trackActivity('Project Detail', { event_type: 'Tab Clicked', event_desc: 'Clicked on Expense Management Tab' })} />:null
        }
        { getLocalStorageInfo()?.user?.permission?.task_management ?
          <Tab label={<span>Task Management</span>} value="task-management" onClick={() => trackActivity('Project Detail', { event_type: 'Tab Clicked', event_desc: 'Clicked on Task Management Tab' })} />:null
        }
        <Tab label={<span>Project Users</span>} value="project-users" onClick={() => trackActivity('Project Users', { event_type: 'Tab Clicked', event_desc: 'Clicked on Project Users Tab' })} />
      </Tabs>
    </Paper>
  )
}
export default withRouter(ProjectDetailTab);

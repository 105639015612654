import React, {Fragment} from 'react';
import { withRouter } from 'react-router';
import { Avatar, Tooltip } from '@material-ui/core';
import { CircularProgress } from '../../../shared_elements';
import EditIcon from '@material-ui/icons/Edit';
import { dateTransformAllSet, onCheckPermission } from '../../../utils_v2';
const FormList = ({project, form, onAssignEngineer, engineerProfile, onRedirection}) => {
  return(
    <tr onClick={onRedirection} style={{cursor:'pointer'}}>
      <td style={{width: '300px'}}>
        <div className="flex-centered form-progress">
          <CircularProgress value={form.progress ? form.progress:0} />
          <div className='form-name'>
            <p>Form Type</p>
            <h4>{`${form.name}-${form.title}`}</h4>
          </div>
        </div>
      </td>
      <td>
        <p>Status</p>
        <h4>{form.status.label}</h4>
      </td>
      <td>
        <p>Last Activity</p>
        <h4>{dateTransformAllSet(form.updated_at)}</h4>
      </td>
      <td align="right">
        <p>Project Engineer</p>
        { form.engineers?.length ?
          <Fragment>
            <h4 style={{cursor:'pointer'}} className="user-info" onClick={(e) => {e.preventDefault(); e.stopPropagation();engineerProfile(form.engineer.slug)}}>
              {form.engineers.map((engineer) => <span>{engineer.name}</span>)}
              { onCheckPermission('project_management','workorder','U') && project.archive_status !==1 && (project.status && project.status.id && ![7,8].includes(project.status.id)) ? <Tooltip title="Change Project Engineer" arrow><EditIcon color="primary" onClick={(e) => {e.stopPropagation(); e.preventDefault(); onAssignEngineer();}} fontSize="small" /></Tooltip>:'--'}
            </h4>
          </Fragment>
          : onCheckPermission('project_management','workorder','U') && project.archive_status !==1 && (project.status && project.status.id && ![7,8].includes(project.status.id)) ?<span style={{cursor:'pointer'}} onClick={(e) => {e.stopPropagation(); e.preventDefault(); onAssignEngineer()}}>Assign</span>:'--'
        }
      </td>
    </tr>
  )
}
export default withRouter(FormList);

import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, CircularProgress } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import PageLoader from './PageLoader';
const DeletePopUp = ({ modal, title, content, circularloader, toggleModalFn, deleteRecordFn, confirmText, cancelText, cancelColor, confirmColor, isLoading }) => {
  return (
    <div className='delete-dialoge-section'>
      <Dialog
        open={modal}
        onClose={toggleModalFn}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
          {title ? title : 'Delete'}
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className="delete-record-modal" style={isMobile ? { width: 'auto' } : { minWidth: '400px' }}>
            <div className="">
              {content}
            </div>
            <div className="actions-cta">
              <Button variant="outlined" onClick={toggleModalFn} color={cancelColor ? cancelColor : 'primary'}>{cancelText ? cancelText : 'Cancel'}</Button>
              <Button variant="contained" onClick={deleteRecordFn} color={confirmColor ? confirmColor : "secondary"} >
                {circularloader ? <CircularProgress size={20} /> : (confirmText ? confirmText : 'Delete')}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {isLoading ? <PageLoader /> : null}
    </div>
  )
}
export default DeletePopUp;

import React from 'react';
import { browserHistory, withRouter } from 'react-router'
import { TableRow, TableCell, Checkbox, Chip,Tooltip } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import moment from 'moment';
import { onCheckFormPermission } from '../../../utils_v2';
const IssueList = ({params, filter, item, bulkOps, onDelete, onChangeBulkOps,currentFormDetail,page}) => {
    console.log("item",item)
    const redirectDetail = () => {
        let location = browserHistory.getCurrentLocation();
        location = {
          ...location,
          pathname:`/${params.type}/${params.aircraft_slug}/technical/${params.workOrderSlug}/forms/${params.formSlug}/T011/${item.id}`,
          query: {...filter, page}
        }
        browserHistory.push(location);
    }
   
    let statusUpdatedby=item.status_update_by.length? item.status_update_by[0].updated_by.name:null
    return(
        <TableRow>
           { onCheckFormPermission(currentFormDetail) ?<TableCell style={{position:'relative'}}>
                <span className={`prs-status ${item.status.label.toLowerCase()}`} style={{background:item.status.value === 0 ? '#4b82de': item.status.value ===1 ? '#269b2b':item.status.value ===2 ? '#e86642':'#dbcc28'}}></span>
                <div style={{width:'40px'}}>
                    <ul className='list-inline flex-centered'>
                        <li className='list-inline-item' onClick={(e) => {e.preventDefault();e.stopPropagation(); onChangeBulkOps(bulkOps.ids.includes(item.id) ? false:true)}}>
                            <Checkbox checked={bulkOps.ids.includes(item.id)} color='primary' size='small' />
                        </li>
                    </ul>
                </div>
            </TableCell> : null}
            <TableCell style={{position:'relative'}}>
                <div>
                    <span style={{fontSize:'11px'}}>{item?.unique_code || ''}</span>
                    <p className="multiline-clamp" style={{width:'140px'}}> {item?.title || '--'}</p>
                </div>
            </TableCell>
            <TableCell>{item?.files?.length || '--'}</TableCell>
            <TableCell>{item?.gap_report_section?.name || ''}</TableCell>
            <TableCell>{item?.priority?.label || 'None'}</TableCell>
            <TableCell>
                <Chip size='small' label={item.status ? item.status.label:'None'} style={{background:item.status.value === 0 ? '#4b82de': item.status.value ===1 ? '#269b2b': item.status.value === 2 ? '#e86642':'#dbcc28', color:'#fff'}} />
            </TableCell>
            <TableCell>{statusUpdatedby||'--'}</TableCell>
            <TableCell>{item?.assignee?.name || 'None' }</TableCell>
            <TableCell>{item.started_date?moment(item.started_date).format('DD-MM-Y'):'--'}</TableCell>
            <TableCell>{item?.created_by?.name || '--'}</TableCell>
            <TableCell>{item?.part_number || '--'}</TableCell>
            <TableCell>{item?.serial_number || '--'}</TableCell>
            <TableCell>
            <div style={{width:'50px'}}>
            { onCheckFormPermission(currentFormDetail) ?
              <ul className='list-inline'>
                        <li className='list-inline-item'>
                         <Tooltip title='Edit' arrow><EditIcon onClick={redirectDetail} color='primary' fontSize='small' /></Tooltip>
                        </li>
                            <li className='list-inline-item'>
                             <DeleteOutlineIcon onClick={(e) => {e.preventDefault(); e.stopPropagation();onDelete()}} color='secondary' fontSize='small' />
                           </li>                         
                    </ul>:null}
             </div>
            </TableCell>

        </TableRow>
    )
}
export default withRouter(IssueList);
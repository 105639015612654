import React, { Fragment, useState } from 'react';
import moment from 'moment';
import { TableRow, TableCell, Tooltip } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { RevisionHistory } from '../../../shared_elements';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { displayDateFormatShort } from '../../../constants'
import { getLocalStorageInfo, onCheckPermission } from '../../../utils_v2';
const userInfo = getLocalStorageInfo();
const ExpenseList = ({budgetExpenses, item, index, onEditExpense, onDeleteExpense, showAttachments, onChangeStatus}) => {
  const [open, setOpen] = useState(false);
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{index+1}</TableCell>
      <TableCell>
        <div style={{width:'80px'}}>#{item.id}</div>
      </TableCell>
      <TableCell>
        <div style={{width:'100px'}}>
          {item.asset && item.asset.name ? item.asset.name : '--'}
        </div>
      </TableCell>
       <TableCell>
        <div style={{width:'100px'}}>
          {item.category && item.category.name ? item.category.name : '--'}
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'120px'}}>
          {item.sub_category && item.sub_category.name ? item.sub_category.name : '--'}
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'100px'}}>
          {item.status ? <span className={`expense-status ${item.status.toLowerCase()}`}>{item.status}</span> : '--'}
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'100px'}}>
          {item?.created_by?.name||'--'}
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'100px'}}>
          {item.user && item.user.name ? item.user.name : '--'}
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'100px'}}>
          {item.approver && item.approver.name ? item.approver.name : '--'}
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'100px'}}>
          {item.from_date ? moment(item.from_date).format(displayDateFormatShort) : '--'}
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'100px'}}>
        {item.to_date ? moment(item.to_date).format(displayDateFormatShort) : '--'}
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'100px'}}>
          {item.location ? item.location : '--'}
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'150px'}}>
          {item.currency && item.currency.code ? item.currency.code : '--'} &nbsp;
          <NumericLabel params={{justification:'L',precision:2,commafy:true}}>{item.bill_amount ? item.bill_amount : 0}</NumericLabel>
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'100px'}}>
          {item.conversion_rate? item.conversion_rate : '--'}
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'150px'}}>
          {item.amount_currency && item.amount_currency.code ? item.amount_currency.code : '--'} &nbsp;
          <NumericLabel params={{justification:'L',precision:2,commafy:true}}>{item.amount ? item.amount : 0}</NumericLabel>
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'150px'}}>
          { item.approved_amount ?
            <Fragment>
              {item.amount_currency && item.amount_currency.code ? item.amount_currency.code : ''} &nbsp;
              <NumericLabel params={{justification:'L',precision:2,commafy:true}}>{item.approved_amount ? item.approved_amount : 0}</NumericLabel>
            </Fragment>:'--'
          }
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'280px'}}>
          {item.remarks ? item.remarks.trim().length > 50 ? open ? item.remarks:item.remarks.slice(0, 47)+' ... ': item.remarks :''}
          {item.remarks.trim().length > 50 ?
            <Fragment>
              { open ? <span style={{color:'#2e0fff', fontSize:'13px', textDecoration:'underline', cursor:'pointer'}} onClick={() => setOpen(false)}> Show Less</span>: <span style={{color:'#2e0fff', fontSize:'13px', textDecoration:'underline', cursor:'pointer'}} onClick={() => setOpen(true)}> Show More</span>}
            </Fragment>:null
          }
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'100px'}}>
          {item.attachments && item.attachments.length ?
            <span onClick={showAttachments} className='flex-centered' style={{cursor:'pointer'}}>
              <AttachmentIcon fontSize='small' />
              ({item.attachments.length})
            </span>:null
          }          
        </div>
      </TableCell>
      <TableCell className='actions-cell'>
        <div style={{width:'100px'}}>
          {onCheckPermission('expense_management', 'expense', 'STS') ?
            <>
              {budgetExpenses?.permission?.status?.value === 2 ?
                <Tooltip title='Change Status' arrow>
                  <AccountTreeIcon onClick={onChangeStatus} color='primary' fontSize='small' />
                </Tooltip>:
                <>
                  {(item?.approver?.id === userInfo.user.id) ?
                    <Tooltip title='Change Status' arrow>
                      <AccountTreeIcon onClick={onChangeStatus} color='primary' fontSize='small' />
                    </Tooltip>:null
                  }
                </>
              }
            </>:null
          }
          <RevisionHistory buttonType={true} url='audit/technical/tcbudgetexpenses/' queryParams={{action:1, object_id:item.id}} />
          {onCheckPermission('expense_management', 'expense', 'R') ?
            <span>
              <Tooltip title='View' arrow>
                <VisibilityIcon onClick={() => onEditExpense('view')} color='primary' fontSize='small' />
              </Tooltip>
            </span>
            : null
          }
          <Fragment>
            {onCheckPermission('expense_management', 'expense', 'U') && item?.status !== 'Approved' && item?.status !== 'Paid' ?
              <>
                { budgetExpenses?.permission?.update?.value === 2 ?
                  <span>
                    <Tooltip title='Edit' arrow>
                      <EditIcon onClick={() => onEditExpense('edit')} color='primary' fontSize='small' />
                    </Tooltip>
                  </span>:
                  <>
                    { (item?.created_by?.id === userInfo.user.id) || (item?.approver?.id === userInfo.user.id) ?
                      <span>
                        <Tooltip title='Edit' arrow>
                          <EditIcon onClick={() => onEditExpense('edit')} color='primary' fontSize='small' />
                        </Tooltip>
                      </span>:null
                    }
                  </>
                }
              </>: null
            }
            { onCheckPermission('expense_management', 'expense', 'D') && item?.status !== 'Approved' && item?.status !== 'Paid' ?
              <>
                { budgetExpenses?.permission?.delete?.value === 2 ?
                  <span>
                    <Tooltip title='Delete' arrow>
                      <DeleteOutlined onClick={onDeleteExpense} color='secondary' fontSize='small' />
                    </Tooltip>
                  </span>:
                  <>
                    {(item?.created_by?.id === userInfo.user.id) || (item?.approver?.id === userInfo.user.id) ?
                      <span>
                        <Tooltip title='Delete' arrow>
                          <DeleteOutlined onClick={onDeleteExpense} color='secondary' fontSize='small' />
                        </Tooltip>
                      </span>:null
                    }
                  </>
                }
              </>:null
            }
          </Fragment>
        </div>
      </TableCell>
    </TableRow>
  )
}
export default ExpenseList;
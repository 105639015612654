import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { PageLoader, ExportManu } from '../../../shared_elements';
import {  GeneralDetail, ShopVisitList } from '../components';
import { FormWrapper, FormSkeletonLoader } from '../../Elements';
import { checkApiStatus, downloadFileType, getLocalStorageInfo } from '../../../utils_v2';
import { globalGetService, globalPostService } from '../../../utils_v2/globalApiServices';
import { globalStaticExportService } from '../../../globalServices';
import { trackActivity } from '../../../utils/mixpanel';
const BuildStandardEvaluation = ({params, location}) => {
  console.log('location',location);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [formLoader, setFormLoader] = useState(true);
  const [tabIndex, setTabIndex] = useState(location?.query?.tabType === 'shop-visits'?'shop-visits':'general-details');
  const [currentFormDetail, setCurrentFormDetail] = useState({});
  useEffect(() => {
    getCurrentFormDetail();
    trackActivity('Project Management', { event_type: 'Page Visited', page_title: 'TE004 Form' });
  },[]);
  const getCurrentFormDetail = (loaderType) => {
    if(loaderType){setFormLoader(true)}
    globalGetService(`technical/engine-workorder/${params.workOrderSlug}/forms/`,{form:params.formSlug})
    .then(response => {
        if(checkApiStatus(response)){
            setCurrentFormDetail(response.data.data)
        }
        if(loaderType){setFormLoader(false)}
    })
  }
  const onChangeFormStatus = (data) => {
    setLoading(true)
    globalPostService(`technical/workorder/${params.workOrderSlug}/form/${params.formSlug}/status-update/`,data)
    .then(response => {
        setLoading(false)
        if(checkApiStatus(response)){
            getCurrentFormDetail('formLoader');
            enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
    });
  }
  const exportFormReport = (file) => {
      enqueueSnackbar('TE004 Report Downloading ...', { autoHideDuration:50000, variant: 'info',anchorOrigin:{horizontal:'right', vertical:'bottom'}})
      let queryParams = {download:file.extension};
      if(file.key){
        queryParams = {...queryParams, summary_export_type:file.key}
      }
      globalStaticExportService(`technical/engine-workorder/${params.workOrderSlug}/shop-visit-details/`, queryParams)
      .then(response => {
          downloadFileType(response, (`TE004_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}.`), file.extension);
          closeSnackbar();
      });
  }
  return(
    <section className='projects-management-apps project-forms-section'>
      { currentFormDetail && Object.keys(currentFormDetail).length ?
        <FormWrapper 
            hideAction={currentFormDetail?.current_form.progress === 0 ? true:false}
            formInfo={currentFormDetail} 
            exportReportBtn={<ExportManu disabled={currentFormDetail?.current_form.progress === 0 ? true:false} exportReportFn={(file) => exportFormReport(file)} title="Export Report" files={[{title:'Evaluation Report', extension: 'pdf', key:''},{title:'Executive Report', extension: 'pdf', key:'executive'}, {title:'Advisory Report', extension: 'pdf', key:'advisory'}]} />}
            updateFormStatusFn={(data) => onChangeFormStatus(data)}
        />:formLoader ? <FormSkeletonLoader />:null
      }
      <div className='technical-engine-forms'>
        <Paper style={{marginBottom:'15px'}}>
          <Tabs
            value={tabIndex}
            onChange={(e, newValue) => setTabIndex(newValue)}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="General Details" value='general-details' />
            <Tab label="Shop Visits  " value='shop-visits' />
          </Tabs>
        </Paper>
        { tabIndex === 'general-details' ? <GeneralDetail currentFormDetail={currentFormDetail} getCurrentFormDetail={()=> getCurrentFormDetail('pageLoader')} />:null }  
        { tabIndex === 'shop-visits' ? <ShopVisitList currentFormDetail={currentFormDetail} getCurrentFormDetail={()=> getCurrentFormDetail('pageLoader')} />:null }  
      </div>
      { isLoading ? <PageLoader />: null }
    </section>
  )
}
export default BuildStandardEvaluation;
import React, { useState } from 'react';
import { Avatar, CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemIcon, ListItemAvatar, ListItemText, Checkbox, ListItemSecondaryAction, } from '@material-ui/core';
const AssignOprEngineer = ({modal, toggleModalFn, engineers, project, onOprEngineer}) => {
    const [isLoading, setLoading] = useState(false);
    const [assignedEngineer, setAssignedEngineer] = useState(null)
    return(
        <Dialog
            maxWidth="md"
            open={modal}
            onClose={toggleModalFn}
            className='project-management-modal'
        >
            <DialogTitle id="scroll-dialog-title">
                Assign Engineer
            </DialogTitle>
            <DialogContent dividers={true}>
                <div>
                    <List>
                        {(() => {
                            let users = project?.opr_user?.id ? engineers.filter(item => item.id !== project?.opr_user?.id) :engineers
                            if(users.length){
                                return (
                                    users.map((item, index) =>
                                        <ListItem key={index} role={undefined} dense button>
                                            <ListItemAvatar>
                                                <Avatar alt='' src={item.profile_pic} />
                                            </ListItemAvatar>
                                            <ListItemText id={item.id} primary={item.name} />
                                            <ListItemSecondaryAction>
                                            <Checkbox
                                                color="primary"
                                                edge="end"
                                                onChange={(e) => setAssignedEngineer(e.target.checked ? item:null)}
                                                checked={assignedEngineer && assignedEngineer.id === item.id ? true:false}
                                            />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )
                                )
                            }else{
                                return(
                                <li style={{textAlign:'center'}}>
                                    <h5 style={{fontSize:'15px', padding:'20px 0'}}>No more engineer found</h5>
                                </li>
                                )
                            }
                        })()}
                    </List>
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} color="primary" onClick={toggleModalFn} size="small">Cancel</Button>
                { assignedEngineer?.id ?
                    <Button onClick={() => onOprEngineer(assignedEngineer, 'assign')} disabled={isLoading} variant="contained" color="primary" size="small">
                        {isLoading ? <CircularProgress size={20} /> : 'Save'}
                    </Button>:null
                }
            </DialogActions>
        </Dialog>
    )
}
export default AssignOprEngineer;
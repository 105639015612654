import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
const FindingRow = ({previewType, index, item, onEdit, onDelete, onView, openAttachments, onHoverEvent}) => {
    return(
        <TableRow>
            <TableCell 
                onMouseLeave={(e) => onHoverEvent('leave')} 
                onMouseEnter={(e) => onHoverEvent('enter')}>
                    {index}
            </TableCell>
            <TableCell>{item?.section?.name||'--'}</TableCell>
            <TableCell>{item?.finding_type||'--'}</TableCell>
            {previewType === 'brief' ? 
                <>
                    <TableCell>{item?.damage_dimension||'--'}</TableCell>
                    <TableCell>{item?.damage_limit||'--'}</TableCell>    
                </>:null
            }
            <TableCell>{item?.remarks||'--'}</TableCell>
            {previewType === 'brief' ?
                <>
                    <TableCell>{item?.classifications||'--'}</TableCell>
                    <TableCell>{item?.category||'--'}</TableCell>
                    <TableCell>{item?.repair_type||'--'}</TableCell>
                    <TableCell>{item?.intervals||'--'}</TableCell>
                    <TableCell>
                        {item?.images?.length ? <>({item?.images?.length}) <AttachFileIcon onClick={openAttachments} fontSize='small'/></> : ''}
                    </TableCell>
                </>:null
            }
            <TableCell align='right' width='100'>
                <ul className='list-inline'>
                    <li className='list-inline-item'>
                        <VisibilityIcon onClick={onView} color='primary' fontSize='small' />
                    </li>
                    <li className='list-inline-item'>
                        <EditIcon onClick={onEdit} color='primary' fontSize='small' />
                    </li>
                    <li className='list-inline-item'>
                        <DeleteOutlineIcon onClick={onDelete} color='secondary' fontSize='small' />
                    </li>
                </ul>
            </TableCell>
        </TableRow>
    )
}
export default FindingRow;
import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { ExportManu, PageLoader } from '../../../shared_elements';
import { SectionCheckList, ObservationList } from '../components'
import { FormSkeletonLoader, FormWrapper } from '../../Elements';
import { checkApiStatus, downloadFileType, getLocalStorageInfo } from '../../../utils_v2';
import { trackActivity } from '../../../utils/mixpanel';
import { globalGetService, globalPostService } from '../../../utils_v2/globalApiServices';
import { globalStaticExportService } from '../../../globalServices';
const exportReports = [
    {title:'Observation', extension: 'pdf', key:'observation'},
    {title:'Summary', extension: 'pdf', key:'summary'},
    {title:'Summary & Observation', extension: 'pdf', key:'both'},
    {title:'Observation', extension: 'docx', key:'observation'},
    {title:'Summary', extension: 'docx', key:'summary'},
    {title:'Summary & Observation', extension: 'docx', key:'both'},
]
const ObservationChecklist = ({params}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [formLoader, setFormLoader] = useState(true);
    const [tabIndex, setTabIndex] = useState(0);
    const [skeletonLoader, setSkeletonLoader] = useState(true);
    const [currentFormDetail, setCurrentFormDetail] = useState(null);
    useEffect(() => {
        getCurrentFormDetail('formLoader');
        trackActivity('Project Management', {
            event_type: 'Page Visited',
            page_title: 'T004 Form'
        });
    },[]);
    const getCurrentFormDetail = (loaderType) => {
        if(loaderType){setFormLoader(true)}
        globalGetService(`technical/${params.type === 'engine' ? 'engine-workorder' :'workorder'}/${params.workOrderSlug}/forms/?asset_type=${params.type}`,{form:params.formSlug,fields:'id,name,project_number,start_date,expected_end_date,slug'})
        .then(response => {
            if(loaderType){setFormLoader(false)}
            if(checkApiStatus(response)){
                setCurrentFormDetail(response.data.data)
            }
        })
    }
    const onChangeFormStatus = (data) => {
        setLoading(true)
        globalPostService(`technical/workorder/${params.workOrderSlug}/form/${params.formSlug}/status-update/?asset_type=${params.type}`,data)
        .then(response => {
            setLoading(false)
            if(checkApiStatus(response)){
                getCurrentFormDetail('formLoader');
                enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                trackActivity('Project Management', {
                    event_type: 'Form Status Updated',
                    page_title: 'T004 Form',
                    form_slug: params.formSlug ? params.formSlug : '',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    status: data.status ? data.status: '',
                    request_body: data ? data : '',
                    success_msg: response.data.message
                });
            }else{
                enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                trackActivity('Project Management', {
                    event_type: 'Form Status Update Failed',
                    page_title: 'T004 Form',
                    form_slug: params.formSlug ? params.formSlug : '',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    status: data.status ? data.status: '',
                    request_body: data ? data : '',
                    error_source: 'Backend',
                    error_msg: response.data.message
                });
            }
        });
    }
    const exportFormReport = (file) => {
        enqueueSnackbar('T004 Report Downloading ...', { variant: 'info',anchorOrigin:{horizontal:'right', vertical:'bottom'}})
        globalStaticExportService(`technical/v2/workorder/${params.workOrderSlug}/t004-audit-report/?asset_type=${params.type}`, {download:file.extension,options:file.key})
        .then(response => {
            downloadFileType(response, (`T004_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}.`), file.extension);
            closeSnackbar();
            trackActivity('Project Management', {
                event_type: 'File Exported',
                page_title: 'T004 Form',
                project_slug: params.project_slug ? params.project_slug : '',
                file_name: `T004_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`,
                file_extension: file.extension,
                success_msg: 'T004 Report Downloaded'
            })
        });
    }
    return(
        <section className='project-forms-section'>
            { currentFormDetail && Object.keys(currentFormDetail).length ?
                <FormWrapper 
                    hideAction={currentFormDetail?.current_form.progress === 0 ? true:false}
                    formInfo={currentFormDetail} 
                    exportReportBtn={<ExportManu disabled={currentFormDetail?.current_form.progress === 0 ? true: false} exportReportFn={(file) => exportFormReport(file)} title="Export Report" files={exportReports} />}
                    updateFormStatusFn={(data) => onChangeFormStatus(data)}
                />:formLoader ? <FormSkeletonLoader />:null
            }
            <div className='observations-form'>
                <Paper>
                    <Tabs indicatorColor='primary' value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)}>
                        <Tab label="Section Summary"/>
                        <Tab label="Observation"/>
                    </Tabs>
                </Paper>
                { tabIndex === 0 ?
                    <div>
                        <SectionCheckList 
                            currentFormDetail={currentFormDetail}
                            getCurrentFormDetail={getCurrentFormDetail}
                        />
                    </div>:null
                }
                {tabIndex === 1 ?
                    <div>
                        <ObservationList 
                            getCurrentFormDetail={getCurrentFormDetail}
                            currentFormDetail={currentFormDetail}
                        />
                    </div>:null
                }
            </div>
            {isLoading ? <PageLoader />:null}
        </section>
    )
}

export default ObservationChecklist;
import React, { useState, Fragment, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Checkbox, Radio, RadioGroup, FormControl, FormLabel,  FormControlLabel, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import AssetInspectionDate from './AssetInspectionDate';
import { GoogleLocationField } from '../../Elements';
import { PageLoader } from '../../../shared_elements';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import { globalGetService, globalPostService, globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
import { aircraftWorkOrder, errorCode } from '../';
import { trackActivity } from '../../../utils/mixpanel';
const AircraftCRU = ({params, modal, toggleModalFn, assetType, project, id, getResponseBack}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [workOrder, setWorkOrder] = useState({});
  const [blueprints, setBlueprint] = useState([]);
  const [aircraftTypes, setAircraftTypes] = useState([]);
  const [manufactures, setManufactures] = useState([]);
  const [forms, setForms] = useState([]);
  const [inspectionTypes, setInspectionTypes] = useState([]);
  const [assets, setAssets] = useState([])
  const [error, setError] = useState({});
  useEffect(() => {
    if(id){
      globalGetService(`technical/project/${params.project_slug}/workorder/${id}/`)
      .then(response => {
        if(checkApiStatus(response)){
          setWorkOrder(response.data.data)
        }
      });
      // setWorkOrder({
      //   ...workOrder,
      //   id:id
      // });
    }else{
      setWorkOrder({
        ...aircraftWorkOrder,
        start_date:project.start_date,
        expected_end_date:project.expected_end_date
      })
    }
    globalGetService('technical/forms/')
    .then(response => {
      if(checkApiStatus(response)){
        setForms(response.data.data)
      }
    });

    globalGetService('technical/inspection-types/')
    .then(response => {
      if(checkApiStatus(response)){
        setInspectionTypes(response.data.data)
      }
    });

    globalGetService('console/list/', {switcher:true, asset_type:assetType})
    .then(response => {
      if(checkApiStatus(response)){
        setAssets(response.data.data.asset)
      }
    });
    globalGetService('technical/blueprints/')
    .then(response => {
      if(checkApiStatus(response)){
        setBlueprint(response.data.data)
      }
    });
    globalGetService('console/manufacturers/')
    .then(response => {
      if(checkApiStatus(response)){
        setManufactures(response.data.data)
      }
    });
    
    globalGetService('console/aircraft-types/')
    .then(response => {
      if(checkApiStatus(response)){
        setAircraftTypes(response.data.data.aircraftTypes)
      }
    });

  },[]);

  const getWorkOrderDetail = (asset) => {
    globalGetService(`technical/aircraft/${asset.slug}/work-order-details/`)
    .then(response => {
      const { id, msn, slug, ...assetObj } = response.data.data;
      if(checkApiStatus(response)){
        setWorkOrder({...workOrder, ...assetObj})
      }
    });
  }
  const onFieldChange = (event, keyParam, data, secKey) => {
    if(secKey){
      setWorkOrder({...workOrder, [keyParam]:{...workOrder[keyParam], [secKey]:data}})
    }else{
      setWorkOrder({...workOrder, [keyParam]:data})
    }
  }
  const onChangeInspection = (event, keyParam, data) => {
    if(data && data.length){
      globalGetService(`technical/forms/`, {inspection_type:data[data.length-1]})
      .then(response => {
        if(checkApiStatus(response)){
          setWorkOrder({
            ...workOrder, 
            [keyParam]:[data[data.length-1]],
            forms:response.data.data.map(form => form.id)
          });
        }else{
          setWorkOrder({...workOrder, [keyParam]:[data[data.length-1]]});
        }
      })
    }else{
      setWorkOrder({...workOrder, [keyParam]:data})
    }
  }
  const resetErrorKey = (key) => {
    setError({
      ...error,
      [key]:''
    }) 
  }
  
  const onAddEditWorkOrder = () => {
    let validationInputs = {
      asset: workOrder.aircraft_id ? '':'Please select Aircraft',
      start_date:workOrder.start_date ? moment(workOrder.start_date, backendDateFormat, true).isValid() ? '':'Please enter valid Date' :'Please enter Start Date',
      expected_end_date:workOrder.expected_end_date ? moment(workOrder.expected_end_date, backendDateFormat, true).isValid() ? '':'Please enter valid Date' :'Please enter End Date',
      status_as_of:workOrder.status_as_of ? moment(workOrder.status_as_of, backendDateFormat, true).isValid() ? '':'Please enter valid Date':'',
      records_inspection_on_start_date:workOrder.records_inspection_on_start_date ? moment(workOrder.records_inspection_on_start_date, backendDateFormat, true).isValid() ? '':'Please enter valid Date':'',
      records_inspection_on_end_date:workOrder.records_inspection_on_end_date ? moment(workOrder.records_inspection_on_end_date, backendDateFormat, true).isValid() ? '':'Please enter valid Date':'',
      physical_inspection_on_start_date:workOrder.physical_inspection_on_start_date ? moment(workOrder.physical_inspection_on_start_date, backendDateFormat, true).isValid() ? '':'Please enter valid Date':'',
      physical_inspection_on_end_date:workOrder.physical_inspection_on_end_date ? moment(workOrder.physical_inspection_on_end_date, backendDateFormat, true).isValid() ? '':'Please enter valid Date':'',
      aircraft_type_id: workOrder.create_msn ? workOrder.aircraft_type_id ? '':'Please select Aircraft Type' :'',
      date_of_manufacture: workOrder.create_msn ? workOrder.date_of_manufacture ? moment(workOrder.date_of_manufacture, backendDateFormat, true).isValid() ? '':'Please enter valid Date' :'Please enter Date of Manufacture' :'',
    }
    if(workOrder.start_date && workOrder.expected_end_date){
      if(moment(workOrder.start_date).isAfter(moment(workOrder.expected_end_date))){
        validationInputs['start_date'] = 'Start date should be less then expected end date';
      }

      // start date and end date should be between project start date and end date
      if(moment(workOrder.start_date).isBefore(moment(project.start_date)) || moment(workOrder.start_date).isAfter(moment(project.expected_end_date))){
        validationInputs['start_date'] = 'Start date should be between project start date and end date';
      }
      if(moment(workOrder.expected_end_date).isBefore(moment(project.start_date)) || moment(workOrder.expected_end_date).isAfter(moment(project.expected_end_date))){ 
        validationInputs['expected_end_date'] = 'Expected end date should be between project start date and end date';
      }
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      setLoading(true);
      if(id){
        globalPutService(`technical/project/${params.project_slug}/workorder/${workOrder.slug}/`,workOrder)
        .then(response => {
          setLoading(false);
          if(checkApiStatus(response)){
            enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
            getResponseBack();
            trackActivity('Project Management', {
              event_type: 'Work Order Edit Success',
              page_title: 'Project Detail',
              project_slug: params.project_slug ? params.project_slug : '',
              workorder_slug: workOrder.slug ? workOrder.slug : '',
              id:id,
              request_body: workOrder ? workOrder : '',
              response_msg: response.data.message
            });
          }else{
            enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            trackActivity('Project Management', {
              event_type: 'Work Order Edit Failed',
              page_title: 'Project Detail',
              project_slug: params.project_slug ? params.project_slug : '',
              workorder_slug: workOrder.slug ? workOrder.slug : '',
              request_body: workOrder ? workOrder : '',
              response_msg: response.data.message,
              error_source: 'Backend'
            });
          }
        })
      }else{
        globalPostService(`technical/project/${params.project_slug}/workorder/`, workOrder)
        .then(response => {
          setLoading(false);
          if(checkApiStatus(response)){
            enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
            getResponseBack();
            trackActivity('Project Management', {
              event_type: 'Work Order Add Success',
              page_title: 'Project Detail',
              project_slug: params.project_slug ? params.project_slug : '',
              request_body: workOrder ? workOrder : '',
              response_msg: response.data.message
            });
          }else{
            enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            trackActivity('Project Management', {
              event_type: 'Work Order Add Failed',
              page_title: 'Project Detail',
              project_slug: params.project_slug ? params.project_slug : '',
              request_body: workOrder ? workOrder : '',
              response_msg: response.data.message,
              error_source: 'Backend'
            });
          }
        }) 
      }
    }else{
      setError(validationInputs)
      trackActivity('Project Management', {
        event_type: 'Work Order Add Failed',
        page_title: 'Project Detail',
        project_slug: params.project_slug ? params.project_slug : '',
        request_body: workOrder ? workOrder : '',
        error_source: 'Frontend',
        errors: validationInputs ? validationInputs : ''
      });
    }
  }
  return(
    <Dialog
      maxWidth="md"
      open={modal}
      onClose={toggleModalFn}
      aria-labelledby="scroll-dialog-title"
      className='project-management-modal add-edit-work-order-modal'
    >
      <DialogTitle id="scroll-dialog-title">
        {workOrder.id ? 'Edit':'Add'} Aircraft
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={1}>
          <Fragment>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup row aria-label="create_msn" name="create_msn" defaultValue="top">
                  <FormControlLabel value="create_msn" control={<Radio checked={!workOrder.create_msn} onChange={(e) => onFieldChange(e,'create_msn',false)} color="primary" />} label={<span style={{fontSize:'15px'}}>Link Existing MSN</span>} />
                  <FormControlLabel value="create_msn" control={<Radio checked={workOrder.create_msn} onChange={(e) => onFieldChange(e,'create_msn',true)} color="primary" />} label={<span style={{fontSize:'15px'}}>Create New MSN</span>} />
                </RadioGroup>
              </FormControl>
            </Grid>
            { workOrder.create_msn ?
              <Grid item xs={12} md={4}>
                <TextField
                  required
                  id="aircraft_id"
                  label="Serial Number"
                  fullWidth
                  margin="normal"
                  value={workOrder.aircraft_id ? workOrder.aircraft_id:''}
                  InputLabelProps={{shrink: true}}
                  error={error.asset ? true:false}
                  onFocus={() => resetErrorKey('asset')}
                  helperText={error.asset ? error.asset:''}
                  onChange={(e) => onFieldChange(e, 'aircraft_id', e.target.value)}
                  variant='outlined'
                />
              </Grid>
              :
              <Grid item xs={12} md={4}>
                <Autocomplete
                  options = {assets}
                  getOptionLabel={option => option.msn}
                  id="aircraft_id"
                  value={assets.length && workOrder.aircraft_id ? assets.find(asset => asset.slug === workOrder.aircraft_id):null}
                  onChange={(e,value) => {getWorkOrderDetail(value);resetErrorKey('asset')}}
                  renderInput={params => <TextField required {...params} error={error.asset ? true:false} helperText={error.asset ? error.asset:''} label="MSN" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                />
              </Grid>
            }
          </Fragment>
          <Grid item xs={12} md={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                  margin="normal"
                  required
                  id="start_date"
                  label="Start Date"
                  format={fieldDateFormat}
                  fullWidth
                  clearable={true}
                  InputLabelProps={{shrink: true}}
                  error={error.start_date ? true:false}
                  helperText={error.start_date ? error.start_date:''}
                  value={workOrder.start_date ? workOrder.start_date:null}
                  onChange={(data, value) => {onFieldChange(value, 'start_date', data ? moment(data).format(backendDateFormat):data);}}
                  inputVariant='outlined'
                />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                  margin="normal"
                  required
                  id="expected_end_date"
                  label="Expected End Date"
                  format={fieldDateFormat}
                  fullWidth
                  clearable={true}
                  InputLabelProps={{shrink: true}}
                  error={error.expected_end_date ? true:false}
                  helperText={error.expected_end_date ? error.expected_end_date:''}
                  value={workOrder.expected_end_date ? workOrder.expected_end_date:null}
                  onChange={(data, value) => {onFieldChange(value, 'expected_end_date', data ? moment(data).format(backendDateFormat):data);}}
                  inputVariant='outlined'
                />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              disabled={!workOrder.create_msn}
              options = {aircraftTypes}
              getOptionLabel={option => option.name}
              id="aircraft_type_id"
              value={aircraftTypes.length && workOrder.aircraft_type_id ? aircraftTypes.find((item) => item.id === workOrder.aircraft_type_id):null}
              onChange={(e, value) => setWorkOrder({...workOrder, aircraft_type_id:value?value.id:null, blueprint_id:value?value.blueprint_id:null,manufacturer_id:value?value.manufacturer_id:null, no_of_gears:value? value.no_of_landing_gears:''})}
              renderInput={params => <TextField required {...params} error={error.aircraft_type_id ? true:false} helperText={error.aircraft_type_id ? error.aircraft_type_id:''} label="Aircraft Model" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              options = {blueprints}
              getOptionLabel={option => option.name}
              id="blueprint_id"
              value={blueprints.length && workOrder.blueprint_id ? blueprints.find((item) => item.id === workOrder.blueprint_id):null}
              onChange={(e, value) => onFieldChange(e, 'blueprint_id', value ?value.id :value)}
              renderInput={params => <TextField {...params} label="Aircraft Blueprint" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
            />
          </Grid>
          
          <Grid item xs={12} md={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                disabled={!workOrder.create_msn}
                margin="normal"
                required
                id="date_of_manufacture"
                label="Date of Manufacture"
                format={fieldDateFormat}
                fullWidth
                disableFuture={true}
                clearable={true}
                InputLabelProps={{shrink: true}}
                error={error.date_of_manufacture ? true:false}
                helperText={error.date_of_manufacture ? error.date_of_manufacture:''}
                value={workOrder.date_of_manufacture ? workOrder.date_of_manufacture:null}
                onChange={(data, value) => {onFieldChange(value, 'date_of_manufacture', data ? moment(data).format(backendDateFormat):data);}}
                inputVariant='outlined'
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                  margin="normal"
                  id="lease_start_date"
                  label="Lease Start Date"
                  format={fieldDateFormat}
                  fullWidth
                  disableFuture={true}
                  clearable={true}
                  InputLabelProps={{shrink: true}}
                  value={workOrder.lease_start_date ? workOrder.lease_start_date:null}
                  onChange={(data, value) => {onFieldChange(value, 'lease_start_date', data ? moment(data).format(backendDateFormat):data);}}
                  inputVariant='outlined'
                />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="work_order_number"
              label="Workorder No"
              fullWidth
              margin="normal"
              value={workOrder.work_order_number ? workOrder.work_order_number:''}
              InputLabelProps={{shrink: true}}
              inputProps={{maxLength: 10}}
              onChange={(e) => onFieldChange(e, 'work_order_number', e.target.value)}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="tsn"
              label="TSN"
              fullWidth
              margin="normal"
              value={workOrder.tsn ? workOrder.tsn:''}
              InputLabelProps={{shrink: true}}
              inputProps={{maxLength: 10}}
              onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn', e.target.value) : e.preventDefault()}}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="csn"
              label="CSN"
              fullWidth
              margin="normal"
              value={workOrder.csn ? workOrder.csn:''}
              InputLabelProps={{shrink: true}}
              inputProps={{maxLength: 10}}
              onChange={(e) => {regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'csn', e.target.value) : e.preventDefault() } }
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} md={4}>
            { Object.keys(workOrder).length ?
              <GoogleLocationField 
                onFieldChange={onFieldChange}
                location={workOrder.location?workOrder.location:''}
              />:null
            }
          </Grid>
          { workOrder.create_msn ?
            <Grid item xs={12} md={4}>
              <TextField
                required
                id="country"
                label="Country"
                fullWidth
                margin="normal"
                value={workOrder.country ? workOrder.country:''}
                InputLabelProps={{shrink: true}}
                error={error.country ? true:false}
                helperText={error.country ? error.country:''}
                onChange={(e) => onFieldChange(e, 'country', e.target.value)}
                variant='outlined'
              />
            </Grid>:null
          }
          
          <Grid item xs={12} md={4}>
            <TextField
              id="registration"
              label="Registration"
              fullWidth
              margin="normal"
              value={workOrder.registration ? workOrder.registration:''}
              InputLabelProps={{shrink: true}}
              inputProps={{maxLength: 20}}
              onChange={(e) => onFieldChange(e, 'registration', e.target.value)}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="line_number"
              label="Line Number"
              fullWidth
              margin="normal"
              value={workOrder.line_number ? workOrder.line_number:''}
              InputLabelProps={{shrink: true}}
              onChange={(e) => onFieldChange(e, 'line_number', e.target.value)}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                  margin="normal"
                  id="status_as_of"
                  label="Status As Of"
                  format={fieldDateFormat}
                  fullWidth
                  disableFuture={true}
                  clearable={true}
                  InputLabelProps={{shrink: true}}
                  value={workOrder.status_as_of ? workOrder.status_as_of:null}
                  onChange={(data, value) => {onFieldChange(value, 'status_as_of', data ? moment(data).format(backendDateFormat):data);}}
                  inputVariant='outlined'
                />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              disabled
              options = {manufactures}
              getOptionLabel={option => option.name}
              id="manufacturer_id"
              value={manufactures.length && workOrder.manufacturer_id ? manufactures.find(manufacturer => manufacturer.id === workOrder.manufacturer_id):null}
              onChange={(e, value) => onFieldChange(e, 'manufacturer_id', value ?value.id :value)}
              renderInput={params => <TextField {...params} label="Manufacturer" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="operator_storage_facility"
              label="Operator Storage Facility"
              fullWidth
              margin="normal"
              value={workOrder.operator_storage_facility ? workOrder.operator_storage_facility:''}
              InputLabelProps={{shrink: true}}
              inputProps={{maxLength: 20}}
              onChange={(e) => onFieldChange(e, 'operator_storage_facility', e.target.value)}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="aoc_regulation"
              label="AOC Regulation"
              fullWidth
              margin="normal"
              value={workOrder.aoc_regulation ? workOrder.aoc_regulation:''}
              InputLabelProps={{shrink: true}}
              inputProps={{maxLength: 20}}
              onChange={(e) => onFieldChange(e, 'aoc_regulation', e.target.value)}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="no_of_gears"
              label="No. of Landing Gear"
              fullWidth
              margin="normal"
              value={workOrder.no_of_gears ? workOrder.no_of_gears:''}
              InputLabelProps={{shrink: true}}
              inputProps={{maxLength: 1}}
              onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'no_of_gears', e.target.value): e.preventDefault()} }
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} md={workOrder.create_msn ? 6:4}>
            <Autocomplete
              options = {inspectionTypes}
              getOptionLabel={option => option.name}
              id="inspection_types"
              multiple
              limitTags={1}
              value={inspectionTypes.length && workOrder.inspection_types && workOrder.inspection_types.length ? inspectionTypes.filter(inspectionType => workOrder.inspection_types.includes(inspectionType.id)):[]}
              onChange={(e, value) => onChangeInspection(e, 'inspection_types', value ? value.map(inspection => inspection.id) :value)}
              renderInput={params => <TextField {...params} label="Inspection Types" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
            />
          </Grid>
          <Grid item xs={12} md={workOrder.create_msn ? 6:12}>
            <Autocomplete
              options = {forms}
              getOptionLabel={option =>`${option.name}-${option.title}`}
              id="forms"
              disableCloseOnSelect={true}
              multiple
              value={forms.length && workOrder.forms && workOrder.forms.length ? forms.filter(form => workOrder.forms.includes(form.id)):[]}
              onChange={(e, value) => onFieldChange(e, 'forms', value ? value.map(form => form.id) :value)}
              renderInput={params => <TextField {...params} label="Forms" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <div className="work-order-inspection-date">
                  
                  {workOrder.blueprint_id && [8,9].includes(workOrder.blueprint_id) ?
                    <div style={{marginBottom:'15px'}}>
                      <h4>No of Galleys (Upper Deck)</h4>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={4}>
                          <TextField
                            id="no_of_gears_up_fwd"
                            label="Fwd Area"
                            fullWidth
                            margin="normal"
                            value={workOrder.no_of_galleys && workOrder.no_of_galleys.up_fwd ? workOrder.no_of_galleys.up_fwd:''}
                            InputLabelProps={{shrink: true}}
                            inputProps={{maxLength: 1}}
                            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'no_of_galleys', e.target.value, 'up_fwd'): e.preventDefault() } }
                            variant='outlined'
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            id="no_of_gears_up_mid"
                            label="Mid Area"
                            fullWidth
                            margin="normal"
                            value={workOrder.no_of_galleys && workOrder.no_of_galleys.up_mid ? workOrder.no_of_galleys.up_mid:''}
                            InputLabelProps={{shrink: true}}
                            inputProps={{maxLength: 1}}
                            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'no_of_galleys', e.target.value, 'up_mid'):e.preventDefault()} }
                            variant='outlined'
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            id="no_of_gears_up_aft"
                            label="Aft Area"
                            fullWidth
                            margin="normal"
                            value={workOrder.no_of_galleys && workOrder.no_of_galleys.up_aft ? workOrder.no_of_galleys.up_aft:''}
                            InputLabelProps={{shrink: true}}
                            inputProps={{maxLength: 1}}
                            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'no_of_galleys', e.target.value, 'up_aft'): e.preventDefault()} }
                            variant='outlined'
                          />
                        </Grid>
                      </Grid>
                    </div>:null
                  }
                  <h4>{ workOrder.blueprint_id && [8,9].includes(workOrder.blueprint_id) ? 'No of Galleys (Main Deck)':'No of Galleys'}</h4>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        id="no_of_gears_fwd"
                        label="Fwd Area"
                        fullWidth
                        margin="normal"
                        value={workOrder.no_of_galleys && workOrder.no_of_galleys.fwd ? workOrder.no_of_galleys.fwd:''}
                        InputLabelProps={{shrink: true}}
                        inputProps={{maxLength: 1}}
                        onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'no_of_galleys', e.target.value, 'fwd'): e.preventDefault() } }
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        id="no_of_gears_mid"
                        label="Mid Area"
                        fullWidth
                        margin="normal"
                        value={workOrder.no_of_galleys && workOrder.no_of_galleys.mid ? workOrder.no_of_galleys.mid:''}
                        InputLabelProps={{shrink: true}}
                        inputProps={{maxLength: 1}}
                        onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'no_of_galleys', e.target.value, 'mid'):e.preventDefault()} }
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        id="no_of_gears_aft"
                        label="Aft Area"
                        fullWidth
                        margin="normal"
                        value={workOrder.no_of_galleys && workOrder.no_of_galleys.aft ? workOrder.no_of_galleys.aft:''}
                        InputLabelProps={{shrink: true}}
                        inputProps={{maxLength: 1}}
                        onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'no_of_galleys', e.target.value, 'aft'): e.preventDefault()} }
                        variant='outlined'
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="work-order-inspection-date">
                  { workOrder.blueprint_id && [8,9].includes(workOrder.blueprint_id) ?
                    <div style={{marginBottom:'15px'}}>
                      <h4>No of Lavatories (Upper Deck)</h4>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={4}>
                          <TextField
                            id="no_of_lavatories_up_fwd"
                            label="Fwd Area"
                            fullWidth
                            margin="normal"
                            value={workOrder.no_of_lavatories && workOrder.no_of_lavatories.up_fwd ? workOrder.no_of_lavatories.up_fwd:''}
                            InputLabelProps={{shrink: true}}
                            inputProps={{maxLength: 1}}
                            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'no_of_lavatories', e.target.value, 'up_fwd') : e.preventDefault() } }
                            variant='outlined'
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            id="no_of_lavatories_up_mid"
                            label="Mid Area"
                            fullWidth
                            margin="normal"
                            value={workOrder.no_of_lavatories && workOrder.no_of_lavatories.up_mid ? workOrder.no_of_lavatories.up_mid:''}
                            InputLabelProps={{shrink: true}}
                            inputProps={{maxLength: 1}}
                            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'no_of_lavatories', e.target.value, 'up_mid') : e.preventDefault()} }
                            variant='outlined'
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            id="no_of_lavatories_up_aft"
                            label="Aft Area"
                            fullWidth
                            margin="normal"
                            value={workOrder.no_of_lavatories && workOrder.no_of_lavatories.up_aft ? workOrder.no_of_lavatories.up_aft:''}
                            InputLabelProps={{shrink: true}}
                            inputProps={{maxLength: 1}}
                            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'no_of_lavatories', e.target.value, 'up_aft') : e.preventDefault() } }
                            variant='outlined'
                          />
                        </Grid>
                      </Grid>
                    </div>
                    :null
                  }
                  <h4>{ workOrder.blueprint_id && [8,9].includes(workOrder.blueprint_id) ? 'No of Lavatories (Main Deck)':'No of Lavatories'}</h4>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        id="no_of_lavatories_fwd"
                        label="Fwd Area"
                        fullWidth
                        margin="normal"
                        value={workOrder.no_of_lavatories && workOrder.no_of_lavatories.fwd ? workOrder.no_of_lavatories.fwd:''}
                        InputLabelProps={{shrink: true}}
                        inputProps={{maxLength: 1}}
                        onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'no_of_lavatories', e.target.value, 'fwd') : e.preventDefault() } }
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        id="no_of_lavatories_mid"
                        label="Mid Area"
                        fullWidth
                        margin="normal"
                        value={workOrder.no_of_lavatories && workOrder.no_of_lavatories.mid ? workOrder.no_of_lavatories.mid:''}
                        InputLabelProps={{shrink: true}}
                        inputProps={{maxLength: 1}}
                        onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'no_of_lavatories', e.target.value, 'mid') : e.preventDefault()} }
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        id="no_of_lavatories_aft"
                        label="Aft Area"
                        fullWidth
                        margin="normal"
                        value={workOrder.no_of_lavatories && workOrder.no_of_lavatories.aft ? workOrder.no_of_lavatories.aft:''}
                        InputLabelProps={{shrink: true}}
                        inputProps={{maxLength: 1}}
                        onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'no_of_lavatories', e.target.value, 'aft') : e.preventDefault() } }
                        variant='outlined'
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <AssetInspectionDate
            workOrder={workOrder}
            onFieldChange={onFieldChange}
            error={error}
            resetErrorKey={resetErrorKey}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} color="primary" onClick={toggleModalFn} size="small">Cancel</Button>
        <Button disabled={isLoading} onClick={onAddEditWorkOrder} variant="contained" color="primary" size="small">
          {isLoading ? <CircularProgress size={20} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default withRouter(AircraftCRU)


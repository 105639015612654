import * as actions from '../actions';
import { createProject } from '../../Forms/AddFormObjects';
import {createEngine} from '../ProjectFileObj'
const crudObj = {
  type: '',
  flag: false,
  data: {}
};
export default function( state = {
  projectList: [],
  engineForms:[],
  apuForms: [],
  pagination: {},
  projectCrud: createProject,
  workOrderCrud: crudObj,
  apuWorkOrderCrud: crudObj,
  projectExportLoader:false,
  attachmentLoader:false,
  esnlist:{},
  addUserListFlag: false,
  projectEngineer: [],
  projectMembers: [],
  projectMembersAll: [],
  projectLead: [],
  projectLeadAll: [],
  inspectionType: [],
  projectLoader: false,
  genInstruction: [],
  aircraftTypeList: [],
  bluePrintsInfo: [],
  projectCounts:{},
  formList: [],
  projectViewData: {},
  questionsData:{},
  feedbackData:{},
  exportLoader:false,
  questionsLoader:false,
  createEngineLoader:false,
  msnDetails: {},
  updatedForm: {},
  engineDetails:createEngine,
  userDetail : {
    data: {},
    flag: false
  },
  inspUsage:{},
  t012:{
    title: '',
    content: ''
  }
}, action ){
  switch (action.type) {
  case actions.INSP_USAGE:
    return {
      ...state,
      inspUsage: action.payload
    }
  case actions.T012_DATA:
    return {
      ...state,
      t012: action.payload
    }
  case actions.WORKORDER_SIDEBAR:
    return {
      ...state,
      apuWorkOrderCrud: action.payload
    }
  case actions.TOGGLE_PROJECT_SIDEBAR:
    return {
      ...state,
      projectCrud: action.payload
    }
  case actions.ADD_PROJECT:
   return {
      ...state,
      projectList: [action.payload, ...state.projectList],
      projectCrud: createProject
    }
  case actions.UPDATE_WORKORDER:
    return {
      ...state,
      projectViewData: {
        ...state.projectViewData,
        work_orders : [ ...state.projectViewData.work_orders].map(data => data.id == action.payload.id ? action.payload : data)

      },
      workOrderCrud: crudObj,
      apuWorkOrderCrud: crudObj
    }
  case actions.UPDATE_USER_TYPES:
    return {
      ...state,
      addUserListFlag: action.payload
    }
  case actions.CREATE_WORKORDER:
    return {
      ...state,
      projectViewData: {
        ...state.projectViewData,
        work_orders : [ action.payload, ...state.projectViewData.work_orders]

      },
      workOrderCrud: {data: {}, flag: false, type: 'add'},
      apuWorkOrderCrud: {data: {}, flag: false, type: 'add'},
    }
  case actions.TOGGLE_WORKORDER_SIDEBAR:
    return {
      ...state,
      workOrderCrud: action.payload
    }
  case actions.TOGGLE_APUWORKORDER_SIDEBAR:
    return {
      ...state,
      apuWorkOrderCrud: action.payload
    }
  case actions.PROJECTS_LIST:
    return {
      ...state,
      projectList: action.payload.list,
      pagination:action.payload.pagination,
      projectCounts:action.payload.projects_count
    }
  case actions.PROJECT_EXPORT_LOADER:
  return{
    ...state,
    projectExportLoader:action.payload
  }
  case actions.ATTCHMENT_ENGINE_LOADER:
  return{
    ...state,
    attachmentLoader:action.payload
  }
  case actions.PROJECT_MEMBERS:
    return{
      ...state,
      [action.payload.type]: action.payload.data
    }
  case actions.UPDATE_PROJECT:
    return {
      ...state,
      projectList: state.projectList.map(data => data.id == action.payload.id ? action.payload : data),
      projectCrud: crudObj
    }
  case actions.GET_GEN_INFO:
    return{
      ...state,
      genInstruction: action.payload
    }
  case actions.PROJECT_LOADER:
    return {
      ...state,
      projectLoader: action.payload
    }
   case actions.AIRCRAFT_TYPE:
    return {
      ...state,
      aircraftTypeList: action.payload
    }
  case actions.BLUE_PRINT_INFO:
   return {
     ...state,
     bluePrintsInfo: action.payload
   }
  case actions.INSPECTION_TYPE:
    return{
      ...state,
      inspectionType: action.payload
    }
  case actions.FORM_LIST:
    return{
      ...state,
      formList: action.payload
    }
  case actions.PROJECT_VIEW_DATA:
    return {
      ...state,
      projectViewData: action.payload
    }
  case actions.PROJECT_QUESTIONS:
    return {
      ...state,
      questionsData: action.payload
    }
  case actions.FEEDBACKLIST:
    return {
      ...state,
      feedbackData: action.payload
    }
  case actions.COR_CLIENT_STATUS:
    return {
      ...state,
      ...state,
      questionsData:{
        ...state.questionsData,
        client_cor_show:action.payload
      }
    }
  case actions.QUESTION_LOADER:
    return {
      ...state,
      questionsLoader: action.payload
    }
    case actions.COR_EXPORT_LOADER:
    return {
      ...state,
      exportLoader: action.payload
    }
    case actions.CREATE_ENGINE_LOADER:
    return {
      ...state,
      createEngineLoader: action.payload
    }


  case actions.REMOVE_ENGINEER:
    return {
      ...state,
      projectViewData: {...state.projectViewData, engineers: state.projectViewData.engineers.filter(item => item.id != action.payload)}
    }
  case actions.UPDATE_ANSWERE:
  function updateAnswer(answers,userId, key, value){
    let newAnswers = answers;
    if(answers.filter(answer => answer.user_id == userId).length){
      newAnswers = newAnswers.map(newAnswer => newAnswer.user_id == userId ? {...newAnswer, [key]: value} : newAnswer)
    }else{
     let answer = {
        [key]: value,
        user_id: userId
      }
      newAnswers = [...newAnswers, answer]
    }
    return newAnswers;
  }
    return{
      ...state,
      questionsData:{
        ...state.questionsData,
         sections:state.questionsData.sections.map((section,sectionIndex)=> sectionIndex==action.payload.sectionIndex?
          {
            ...section, questions:section.questions.map((question,questionIndex)=> questionIndex==action.payload.questionIndex ?
          {...question, answers: updateAnswer(question.answers,action.payload.userId,action.payload.key,action.payload.value)
          }
          :question
          )
          }

         :section
         )

      }
    }
  case actions.UPDATE_FEEDBACKS:
  function updateAnswerAc(answers,userId, key, value){
    let newAnswers = answers;
    if(answers.filter(answer => answer.user_id == userId).length){
      newAnswers = newAnswers.map(newAnswer => newAnswer.user_id == userId ? {...newAnswer, [key]: value} : newAnswer)
    }else{
     let answer = {
        [key]: value,
        user_id: userId
      }
      newAnswers = [...newAnswers, answer]
    }
    return newAnswers;
  }
  return{
    ...state,
    feedbackData:{
      ...state.feedbackData,
      questions:state.feedbackData.questions.map((question,questionIndex)=> questionIndex==action.payload.questionIndex ?
      {...question, answers: updateAnswerAc(question.answers,action.payload.userId,action.payload.key,action.payload.value)
      }
      :question
      )
    }
  }
  case actions.MSN_DETAILS:
    return {
      ...state,
      msnDetails: action.payload
    }
  case actions.FORM_UPDATED_LIST:
    return{
      ...state,
      updatedForm: action.payload
    }
  case actions.ENGINE_DETAILS:
    return{
      ...state,
      engineDetails: action.payload
    }

  case actions.ATTACH_UPLOAD_FILE_CREATE:
    return{
     ...state,
     engineDetails : {
       ...state.engineDetails,
         attachments : [...state.engineDetails.attachments, action.payload]
     }
   }
   case actions.ATTACH_UPLOAD_FILE_SHOP:
   return{
    ...state,
    engineDetails : {
      ...state.engineDetails,
      shop_visits : [...state.engineDetails.shop_visits].map((shopDetails,index)=> index== action.payload.index ?{
        ...shopDetails,
        attachments:[...shopDetails.attachments, action.payload.data]
      }:shopDetails
      )
    }
  }
 case actions.FETCH_ENGINE_FORMS:
  return{
   ...state,
   engineForms: action.payload
 }
 case actions.FETCH_APU_FORMS:
  return{
   ...state,
   apuForms: action.payload
 }
 case actions.FETCH_EXISTING_ENGINES:
  return{
   ...state,
   esnlist: action.payload
 }
  case actions.REFERENCE_FILE_ADD:
    return{
     ...state,
     engineDetails : {
       ...state.engineDetails,
       reference_links : [...state.engineDetails.reference_links, action.payload]
     }
   }
   case actions.ADD_SHOP_VISIT:
    return{
     ...state,
     engineDetails : {
       ...state.engineDetails,
       shop_visits : [...state.engineDetails.shop_visits, action.payload]
     }
   }


  case actions.UPDATE_CREATE_ENGINE:
    if(action.payload.sectionType == 'EngineCurrentDetails'){
        return{
        ...state,
        engineDetails: {
          ...state.engineDetails,
            [action.payload.type]: action.payload.value
          }
      }
    }else if (action.payload.sectionType == 'attachment'){
      return{
        ...state,
        engineDetails: {
          ...state.engineDetails,
          attachments:[...state.engineDetails.attachments].map((attachment, index)=>index==action.payload.index ?{
            ...attachment,
            [action.payload.type]: action.payload.value
           }:attachment
           )
        }
      }
    } else if(action.payload.sectionType == 'reference_links'){
      return{
        ...state,
        engineDetails: {
          ...state.engineDetails,
          reference_links:[...state.engineDetails.reference_links].map((reference, index)=>index==action.payload.index ?{
            ...reference,
            [action.payload.type]: action.payload.value
           }:reference
           )
        }
      }
    } else if(action.payload.sectionType == 'forms'){
      return{
        ...state,
        engineDetails: {
          ...state.engineDetails,
          forms:action.payload.value
        }
      }
    }
    else if(action.payload.sectionType == 'shop_visit'){
      return{
        ...state,
        engineDetails: {
          ...state.engineDetails,
          shop_visits:[...state.engineDetails.shop_visits].map((shopDetails, index)=>index==action.payload.index ?{
            ...shopDetails,
            [action.payload.type]: action.payload.value
           }:shopDetails
           )
        }
      }
    }
    else if(action.payload.sectionType == 'shop_attach'){
      return{
        ...state,
        engineDetails: {
          ...state.engineDetails,
          shop_visits:[...state.engineDetails.shop_visits].map((shopDetails, index)=>index==action.payload.parentIndex ?{
            ...shopDetails,
            attachments:[...shopDetails.attachments].map((attachment,attachIndex)=>attachIndex==action.payload.index ?{
              ...attachment,
              [action.payload.type]: action.payload.value
            }:attachment
            )
           }:shopDetails
           )
        }
      }
    }

  case actions.UPDATE_NEW_EMPTY_ENGINE:
  return{
    ...state,
    engineDetails: createEngine
  }

  case actions.USER_DETAIL:
    return{
      ...state,
      userDetail: action.payload
    }
  case actions.CLOSE_USER_MODAL:
    return{
      ...state,
      userDetail: action.payload
    }

  case actions.DELETE_ENG_SHOPVISIT:
    return {
      ...state,
      engineDetails: {
        ...state.engineDetails,
        shop_visits : [...state.engineDetails.shop_visits].filter((shopVisit,shopIndex) => shopIndex != action.payload)
      }
    }

  case actions.DELETE_ENG_ATTACHMENT:
    return {
      ...state,
      engineDetails: {
        ...state.engineDetails,
        attachments : [...state.engineDetails.attachments].filter((attachment,attachIndex) => attachIndex != action.payload)
      }
    }

  case actions.DELETE_ENG_REFERENCE:
    return {
      ...state,
      engineDetails: {
        ...state.engineDetails,
        reference_links : [...state.engineDetails.reference_links].filter((reference,referenceIndex) => referenceIndex != action.payload)
      }
    }

  case actions.DELETE_ENG_SHOPVISIT_ATTACHMENT:
    return {
      ...state,
      engineDetails: {
        ...state.engineDetails,
        shop_visits:[...state.engineDetails.shop_visits].map((shopDetails, index)=>index==action.payload.parentIndex ?{
          ...shopDetails,
          attachments:[...shopDetails.attachments].filter((attachments,shopIndex) => shopIndex != action.payload.index)
         }:shopDetails
         )
      }
    }

    case actions.CLEAR_ESN_DATA:
    return {
      ...state,
      engineDetails: createEngine
    }


  default:
      return state;

  }
}

import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { useSnackbar } from "notistack"
import { Avatar, Button, CircularProgress } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { CkEditorText, DeletePopUp } from '../../../shared_elements';
import { checkApiStatus, getLocalStorageInfo,onCheckFormPermission } from '../../../utils_v2';
import { globalGetService, globalPostService, globalPutService, globalDeleteService } from '../../../utils_v2/globalApiServices';
import moment from "moment";
import { displayDateFormatShort } from "../../../constants";
const Comment = ({item, addEditComment, onDelete, editComment, onUpdateComment, onEditMode,currentFormDetail}) => {
    const [editMode, setEditMode] = useState(false);
    return(
        <div className="comment">
            { editMode ? 
                <div className="add-comment">
                    <div className="comment-box">
                        <CkEditorText 
                            htmlData={editComment.comment} 
                            onChangeData={(data) => onUpdateComment(data)}
                        />
                    </div>
                    <ul className="list-inline">
                        <li className="list-inline-item">
                         <Button onClick={() => {setEditMode(false);}}color="primary" size='small' variant="outlined">Cancel</Button> 
                        </li>
                        <li className="list-inline-item">
                            <Button onClick={() => {setEditMode(false); addEditComment()}} disabled={!item.comment.trim().length} color="primary" size='small' variant="contained">Update Comment</Button>
                        </li>
                    </ul>
                </div>:
                <Fragment>
                    <div className="user-detail flex-centered">
                        <Avatar alt={item.user?item.user.name:""} src="" />
                        <div className="user-name">
                            <h4>{item.user?item.user.name:""}</h4>
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <span>Updated at: {item.updated_at?moment(item.updated_at).format(displayDateFormatShort):""}</span>
                                </li>
                                 {onCheckFormPermission(currentFormDetail) ?
                               <>
                                { getLocalStorageInfo().user.id === item.user.id?
                                    <li className="list-inline-item">
                                      <EditIcon onClick={() => {setEditMode(true); onEditMode()}} color="primary" fontSize="small" /> 
                                    </li>:null
                                }
                                { getLocalStorageInfo().user.id === item.user.id?
                                    <li className="list-inline-item">
                                        <DeleteOutlineIcon onClick={onDelete} color="secondary" fontSize="small" />
                                    </li>:null
                                 }
                                </>
                                : null}
                            </ul>
                        </div>
                    </div>
                    <div className="comment-content" dangerouslySetInnerHTML={{__html: item.comment? item.comment:""}}>
                    </div>
                </Fragment>
            }

        </div>
    )
}
const Comments = ({params, getResponseBack,currentFormDetail}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [isLoading, setLoading] = useState(false);
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState('');
    const [deleteComment, setDeleteComment] = useState({modal:false, id:null});
    const [editComment, setEditComment] = useState({})
    
    useEffect(() => {
        getComments();
    }, []);
    const addEditComment = (data) => {
        let payload = Object.assign({},data);
        if(data.id){
            globalPutService(`technical/workorder/${params.workOrderSlug}/gap-report/${params.id}/comments/${data.id}/?asset_type=${params.type}`, payload)
            .then(response => {
                if(checkApiStatus(response)){
                    enqueueSnackbar(response.data.message, {variant: "success", anchorOrigin: {horizontal: "right", vertical: "top"}});
                    setComments(comments.map(item => item.id !== response.data.data.id? item: response.data.data))
                }else{
                    enqueueSnackbar(response.data.message, {variant: "error", anchorOrigin: {horizontal: "right", vertical: "top"}});
                }
            })
        }else{
            setLoading(true)
            globalPostService(`technical/workorder/${params.workOrderSlug}/gap-report/${params.id}/comments/?asset_type=${params.type}`, payload)
            .then(response => {
                setLoading(false)
                if(checkApiStatus(response)){
                    enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }});
                    setComments([...comments, response.data.data])
                    setComment('')
                }else{  
                    enqueueSnackbar(response.data.message, {variant: "error", anchorOrigin: {horizontal: "right", vertical: "top"}});
                }
            })
        }
    }
    const onDeleteComment = () => {
        setLoading(true)
        globalDeleteService(`technical/workorder/${params.workOrderSlug}/gap-report/${params.id}/comments/${deleteComment.comment.id}/?asset_type=${params.type}`)
        .then(response => {
            setLoading(false)
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }});
                setDeleteComment({modal:false, id:null});
                setComments(comments.filter((item) => item.id !== deleteComment.comment.id))
            }else{
                enqueueSnackbar(response.data.message, {variant: "error", anchorOrigin: {horizontal: "right", vertical: "top"}});
            }
        })
    }
    const getComments = () => {
        globalGetService(`technical/workorder/${params.workOrderSlug}/gap-report/${params.id}/comments/?asset_type=${params.type}`)
        .then(response => {
            if(checkApiStatus(response)){
                setComments(response.data.data)
            }else{
                enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }});
            }
        })
    }
    return(
        <div className="prs-comments-blk">
            {comments.map((item, index) => 
                <Comment 
                    item={item}
                    key={index}
                    editComment={editComment}
                    addEditComment={() => addEditComment(editComment, editComment.id)}
                    onDelete={() => setDeleteComment({modal:true, comment:item})}
                    onEditMode={() => setEditComment(item)}
                    onUpdateComment={(data) => setEditComment({...editComment, comment:data})}
                    currentFormDetail={currentFormDetail}
                    
                />
            )}
            <div className="add-comment">
                <div className="comment-box">
                    <CkEditorText 
                        htmlData={comment}
                        onChangeData={(data) => setComment(data)}
                        disabled={onCheckFormPermission(currentFormDetail) ? false :true}
                    />
                </div>
                <ul className="list-inline">
                    <li className="list-inline-item">
                        {comment.length > 0 && <Button disabled={isLoading} onClick={()=> setComment('')} color="primary" size='small' variant="outlined">Cancel</Button>}
                    </li>
                    <li className="list-inline-item">
                        <Button disabled={isLoading || comment.trim().length === 0} onClick={() => addEditComment({comment: comment})} color="primary" size='small' variant="contained">
                            {isLoading? <CircularProgress size={24}/>: "Submit"}
                        </Button>
                    </li>
                </ul>
            </div>
            <DeletePopUp
                modal={deleteComment.modal}
                toggleModalFn={() => setDeleteComment({modal:false, id:null})}
                title="Delete Comment"
                content={<h4>Are you sure you want to delete?</h4>}
                deleteRecordFn={() => onDeleteComment()}
                confirmText="Delete"
                isLoading={isLoading}
            />
        </div>
    )
}
export default withRouter(Comments);
export const projectFilterOptions = {
  status: {
    inputType: 'dropdown',
    placeholder: 'Select Status',
    options: [{id: 'upcoming', name: 'Up Coming'}, {id: 'ongoing', name: 'On Going'}, { id: 'overdue', name: 'Overdue'}, { id: 'completed', name: 'Completed'}],
    labelKey: 'name',
    valueKey: 'id',
    title: 'Status',
    multiple: true
  },
  department: {
    inputType: 'dropdown',
    placeholder: 'Select Department',
    options: [],
    labelKey: 'label',
    valueKey: 'value',
    title: 'Department',
    multiple: true
  },
  project_type: {
    inputType: 'dropdown',
    placeholder: 'Select Project Type',
    options: [],
    labelKey: 'label',
    valueKey: 'value',
    title: 'Project Type',
    multiple: true
  },
  search:{
    inputType: 'text',
    placeholder: 'Search',
    title: 'Search'
  },
  project_lead:{
    inputType: 'text',
    placeholder: 'Search by Lead',
    title: 'Project Lead'
  },
  project_engineer:{
    inputType: 'text',
    placeholder: 'Search by Engineer',
    title: 'Project Engineer'
  },
  project_no:{
    inputType: 'text',
    placeholder: 'Search by Project No',
    title: 'Project Number'
  },
  project_location:{
    inputType: 'text',
    placeholder: 'Search by Project Location',
    title: 'Project Location'
  },
  serial_number:{
    inputType: 'text',
    placeholder: 'Search by Asset',
    title: 'MSN/ESN'
  },
  operator:{
    inputType: 'text',
    placeholder: 'Search by Asset',
    title: 'Lessee'
  },
  project_date:{
    inputType: 'date',
    title: 'Project Date',
    firstKey:'startDate',
    lastKey:'endDate',
    range:true
  }
}
export const resourceFilterOptions = {
  department: {
    inputType: 'dropdown',
    placeholder: 'Select Department',
    options: [],
    labelKey: 'label',
    valueKey: 'value',
    title: 'Department',
    multiple: true
  },
  user_type: {
    inputType: 'dropdown',
    placeholder: 'Select Resource Status',
    options: [ {value:'available', label: 'Available'}, {value:'deployed' , label: 'Deployed'}],
    labelKey: 'label',
    valueKey: 'value',
    title: 'Resource Status',
    multiple: true
  },
  category: {
    inputType: 'dropdown',
    placeholder: 'Select Category',
    options: [],
    labelKey: 'label',
    valueKey: 'value',
    title: 'Category',
    multiple: true
  },
  user:{
    inputType:'text',
    placeholder:'Search',
    title:'Name of Staff'
  },
  project_name:{
    inputType:'text',
    placeholder:'Search',
    title:'Project Name'
  },
  location:{
    inputType:'text',
    placeholder:'search',
    title:'Location'
  },
  startDate:{
    inputType:'date',
    title: 'Start Date',

  },
  endDate:{
    inputType:'date',
    title: 'End Date',
  },
}
export const projectsFilterOptions = {
  department: {
    inputType: 'dropdown',
    placeholder: 'Select Department',
    options: [],
    labelKey: 'label',
    valueKey: 'value',
    title: 'Project Department',
    multiple: true
  },
  status: {
    inputType: 'dropdown',
    placeholder: 'Select Project Status',
    options: [ {value:'completed', label: 'Completed'}, {value:'ongoing' , label: 'Ongoing'}, { value:'upcoming', label: 'Upcoming'}],
    labelKey: 'label',
    valueKey: 'value',
    title: 'Project Status',
    multiple: true
  },
  name: {
    inputType: 'dropdown',
    placeholder: 'Select Project',
    options: [],
    labelKey: 'label',
    valueKey: 'value',
    title: 'Project Name',
    multiple: false
  },
  date_from:{
    inputType: 'date',
    title: 'Project Start Date'
  },
  date_to:{
    inputType:'date',
    title:'Project End Date'
  },
  
  lessee:{
    inputType:'dropdown',
    title:'Lessee',
    options:[],
    labelKey:'name',
    valueKey:'name',
    placeholder:'Search by lessee'
  },
  project_number:{
    inputType: 'text',
    placeholder: 'Search',
    title: 'Project Name'
  },
  project_leads:{
    inputType:'dropdown',
    title:'Project Leads',
    options:[],
    labelKey:'name',
    valueKey:'id',
    placeholder:'Select Project Leads',
    multiple:true
  },
  lessor_ids :{
    inputType:'dropdown',
    title:'Lessor',
    options:[],
    labelKey:'name',
    valueKey:'id',
    placeholder:'Select Lessor',
    multiple:true
  },
  project_engineers:{
    inputType:'dropdown',
    title:'Project Engineer',
    options:[],
    labelKey:'name',
    valueKey:'id',
    placeholder:'Select Project Engineer',
    multiple:true
  },
  regions:{
    inputType:'text',
    title:'Region or  Project Location',
    placeholder:'Region',
  }
}
export const projectAnalyticHd = [
  { label: 'Index', sortOption: false },
  { label: 'Project No', sortOption: false },
  { label: 'Project', sortOption: true, id:'name' },
  { label:'Project Department', sortOption:'true', id:'department'},
  { label: 'Project Status', sortOption: false },
  { label: 'Project Leads', sortOption: false},
  { label:'Project Engineer', sortOption: false},
  { label: 'No of Assets', sortOption:false },
  { label: 'Lessor', sortOption: false, },
  { label: 'Airline / Lessee', sortOption: false,},
  { label: 'Start Date', sortOption: true, id:'start_date' },
  { label: 'Expected End Date', sortOption: true, id:'expected_end_date' },
  { label: 'Project Location', sortOption: false },
]
export const resourceAnalyticHd = [
  { label: 'Index', sortOption: false },
  { label: 'Name of Staff', sortOption: true, id:'name' },
  { label: 'Status', sortOption: true, id:'status' },
  { label: 'Status Detail', sortOption: true, id:'deployment' },
  { label: 'Projects', sortOption: true, id:'projects' },
  { label: 'Designation', sortOption: true, id:'designation' },
  { label: 'Department', sortOption: true, id:'department' },
  { label: 'Contact No', sortOption: true, id:'contact_in' },
  { label: 'Email', sortOption: true, id:'email' },
  { label: 'Country', sortOption: false },
  { label: 'Base Location', sortOption: true, id:'base_location' },
  { label: 'Current Location', sortOption: false },
]
export const countryCodes = {
  "AF": "Afghanistan",
  "AX": "Aland Islands",
  "AL": "Albania",
  "DZ": "Algeria",
  "AS": "American Samoa",
  "AD": "Andorra",
  "AO": "Angola",
  "AI": "Anguilla",
  "AQ": "Antarctica",
  "AG": "Antigua And Barbuda",
  "AR": "Argentina",
  "AM": "Armenia",
  "AW": "Aruba",
  "AU": "Australia",
  "AT": "Austria",
  "AZ": "Azerbaijan",
  "BS": "Bahamas",
  "BH": "Bahrain",
  "BD": "Bangladesh",
  "BB": "Barbados",
  "BY": "Belarus",
  "BE": "Belgium",
  "BZ": "Belize",
  "BJ": "Benin",
  "BM": "Bermuda",
  "BT": "Bhutan",
  "BO": "Bolivia",
  "BA": "Bosnia And Herzegovina",
  "BW": "Botswana",
  "BV": "Bouvet Island",
  "BR": "Brazil",
  "IO": "British Indian Ocean Territory",
  "BN": "Brunei Darussalam",
  "BG": "Bulgaria",
  "BF": "Burkina Faso",
  "BI": "Burundi",
  "KH": "Cambodia",
  "CM": "Cameroon",
  "CA": "Canada",
  "CV": "Cape Verde",
  "KY": "Cayman Islands",
  "CF": "Central African Republic",
  "TD": "Chad",
  "CL": "Chile",
  "CN": "China",
  "CX": "Christmas Island",
  "CC": "Cocos (Keeling) Islands",
  "CO": "Colombia",
  "KM": "Comoros",
  "CG": "Congo",
  "CD": "Congo, Democratic Republic",
  "CK": "Cook Islands",
  "CR": "Costa Rica",
  "CI": "Cote D\"Ivoire",
  "HR": "Croatia",
  "CU": "Cuba",
  "CY": "Cyprus",
  "CZ": "Czech Republic",
  "DK": "Denmark",
  "DJ": "Djibouti",
  "DM": "Dominica",
  "DO": "Dominican Republic",
  "EC": "Ecuador",
  "EG": "Egypt",
  "SV": "El Salvador",
  "GQ": "Equatorial Guinea",
  "ER": "Eritrea",
  "EE": "Estonia",
  "ET": "Ethiopia",
  "FK": "Falkland Islands (Malvinas)",
  "FO": "Faroe Islands",
  "FJ": "Fiji",
  "FI": "Finland",
  "FR": "France",
  "GF": "French Guiana",
  "PF": "French Polynesia",
  "TF": "French Southern Territories",
  "GA": "Gabon",
  "GM": "Gambia",
  "GE": "Georgia",
  "DE": "Germany",
  "GH": "Ghana",
  "GI": "Gibraltar",
  "GR": "Greece",
  "GL": "Greenland",
  "GD": "Grenada",
  "GP": "Guadeloupe",
  "GU": "Guam",
  "GT": "Guatemala",
  "GG": "Guernsey",
  "GN": "Guinea",
  "GW": "Guinea-Bissau",
  "GY": "Guyana",
  "HT": "Haiti",
  "HM": "Heard Island & Mcdonald Islands",
  "VA": "Holy See (Vatican City State)",
  "HN": "Honduras",
  "HK": "Hong Kong",
  "HU": "Hungary",
  "IS": "Iceland",
  "IN": "India",
  "ID": "Indonesia",
  "IR": "Iran, Islamic Republic Of",
  "IQ": "Iraq",
  "IE": "Ireland",
  "IM": "Isle Of Man",
  "IL": "Israel",
  "IT": "Italy",
  "JM": "Jamaica",
  "JP": "Japan",
  "JE": "Jersey",
  "JO": "Jordan",
  "KZ": "Kazakhstan",
  "KE": "Kenya",
  "KI": "Kiribati",
  "KR": "Korea",
  "KP": "North Korea",
  "KW": "Kuwait",
  "KG": "Kyrgyzstan",
  "LA": "Lao People\"s Democratic Republic",
  "LV": "Latvia",
  "LB": "Lebanon",
  "LS": "Lesotho",
  "LR": "Liberia",
  "LY": "Libyan Arab Jamahiriya",
  "LI": "Liechtenstein",
  "LT": "Lithuania",
  "LU": "Luxembourg",
  "MO": "Macao",
  "MK": "Macedonia",
  "MG": "Madagascar",
  "MW": "Malawi",
  "MY": "Malaysia",
  "MV": "Maldives",
  "ML": "Mali",
  "MT": "Malta",
  "MH": "Marshall Islands",
  "MQ": "Martinique",
  "MR": "Mauritania",
  "MU": "Mauritius",
  "YT": "Mayotte",
  "MX": "Mexico",
  "FM": "Micronesia, Federated States Of",
  "MD": "Moldova",
  "MC": "Monaco",
  "MN": "Mongolia",
  "ME": "Montenegro",
  "MS": "Montserrat",
  "MA": "Morocco",
  "MZ": "Mozambique",
  "MM": "Myanmar",
  "NA": "Namibia",
  "NR": "Nauru",
  "NP": "Nepal",
  "NL": "Netherlands",
  "AN": "Netherlands Antilles",
  "NC": "New Caledonia",
  "NZ": "New Zealand",
  "NI": "Nicaragua",
  "NE": "Niger",
  "NG": "Nigeria",
  "NU": "Niue",
  "NF": "Norfolk Island",
  "MP": "Northern Mariana Islands",
  "NO": "Norway",
  "OM": "Oman",
  "PK": "Pakistan",
  "PW": "Palau",
  "PS": "Palestinian Territory, Occupied",
  "PA": "Panama",
  "PG": "Papua New Guinea",
  "PY": "Paraguay",
  "PE": "Peru",
  "PH": "Philippines",
  "PN": "Pitcairn",
  "PL": "Poland",
  "PT": "Portugal",
  "PR": "Puerto Rico",
  "QA": "Qatar",
  "RE": "Reunion",
  "RO": "Romania",
  "RU": "Russian Federation",
  "RW": "Rwanda",
  "BL": "Saint Barthelemy",
  "SH": "Saint Helena",
  "KN": "Saint Kitts And Nevis",
  "LC": "Saint Lucia",
  "MF": "Saint Martin",
  "PM": "Saint Pierre And Miquelon",
  "VC": "Saint Vincent And Grenadines",
  "WS": "Samoa",
  "SM": "San Marino",
  "ST": "Sao Tome And Principe",
  "SA": "Saudi Arabia",
  "SN": "Senegal",
  "RS": "Serbia",
  "SC": "Seychelles",
  "SL": "Sierra Leone",
  "SG": "Singapore",
  "SK": "Slovakia",
  "SI": "Slovenia",
  "SB": "Solomon Islands",
  "SO": "Somalia",
  "ZA": "South Africa",
  "GS": "South Georgia And Sandwich Isl.",
  "ES": "Spain",
  "LK": "Sri Lanka",
  "SD": "Sudan",
  "SR": "Suriname",
  "SJ": "Svalbard And Jan Mayen",
  "SZ": "Swaziland",
  "SE": "Sweden",
  "CH": "Switzerland",
  "SY": "Syrian Arab Republic",
  "TW": "Taiwan",
  "TJ": "Tajikistan",
  "TZ": "Tanzania",
  "TH": "Thailand",
  "TL": "Timor-Leste",
  "TG": "Togo",
  "TK": "Tokelau",
  "TO": "Tonga",
  "TT": "Trinidad And Tobago",
  "TN": "Tunisia",
  "TR": "Turkey",
  "TM": "Turkmenistan",
  "TC": "Turks And Caicos Islands",
  "TV": "Tuvalu",
  "UG": "Uganda",
  "UA": "Ukraine",
  "AE": "United Arab Emirates",
  "GB": "United Kingdom",
  "US": "United States",
  "UM": "United States Outlying Islands",
  "UY": "Uruguay",
  "UZ": "Uzbekistan",
  "VU": "Vanuatu",
  "VE": "Venezuela",
  "VN": "Vietnam",
  "VG": "Virgin Islands, British",
  "VI": "Virgin Islands, U.S.",
  "WF": "Wallis And Futuna",
  "EH": "Western Sahara",
  "YE": "Yemen",
  "ZM": "Zambia",
  "ZW": "Zimbabwe"
}

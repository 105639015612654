import { regexConstants, errorMsg1 } from '../../constants/regEx';
export const findingObj = {
  section:null,
  finding_type: '',
  damage_dimension: '',
  damage_limit:'',
  remarks:'',
  classifications:'',
  category:'',
  repair_type: '',
  arm_srm_reference:'',
  dd_wo_reference:'',
  corrective_actions:'',
  intervals:''
};
export const findingTypes = [
  {value:"Dent",label:"Dent"},
  {value:"Scratch",label:"Scratch"},
  {value:"Nick",label:"Nick"},
  {value:"Gouge",label:"Gouge"},
  {value:"Erosion",label:"Erosion"},
  {value:"Corrosion",label:"Corrosion"},
  {value:"Puncture",label:"Puncture"},
  {value:"Lightning Strike",label:"Lightning Strike"},
  {value:"Hail strike",label:"Hail strike"},
  {value:"Wave",label:"Wave"},
  {value:"Crack",label:"Crack"},
  {value:"Others", label:"Others"}
];
export const classifications = [
  {value:"Major",label:"Major"},
  {value:"Minor",label:"Minor"},
];
export const category = [
  {value:"A",label:"A"},
  {value:"B",label:"B"},
  {value:"C",label:"C"}
];
export const repairType = [
  {value:"Doubler",label:"Doubler"},
  {value:"Composite",label:"Composite"},
]
export const findingErrorCode = {
    section:{
      0:'',
      1:'Please select Aircraft Section'
    },
    sectionObj:{
      required:true
    },
    finding_type: {
      0: '',
      1:'Please select Damage Type'
    },
    finding_typeObj: {
      required: true
    },
    damage_dimension: {
      0:'',
      4: errorMsg1
    },
    damage_dimensionObj:{
      regexPattern: regexConstants.alphanumericWithHyphenSpace
    },
    damage_limit: {
      0:'',
      4: errorMsg1
    },
    damage_limitObj: {
      regexPattern: regexConstants.alphanumericWithHyphenSpace
    },
    remarks: {
      0: '',
      1: 'Please enter Description',
      4: errorMsg1
    },
    remarksObj: {
      required: true,
      regexPattern: regexConstants.alphanumericWithHyphenSpace
    },
    dd_wo_reference: {
      0:'',
      4: errorMsg1
    },
    dd_wo_referenceObj: {
      regexPattern: regexConstants.alphanumericWithHyphenSpace
    },
    arm_srm_reference: {
      0:'',
      4: errorMsg1
    },
    arm_srm_referenceObj: {
      regexPattern: regexConstants.alphanumericWithHyphenSpace
    },
    corrective_actions: {
      0:'',
      4: errorMsg1
    },
    corrective_actionsObj: {
      regexPattern: regexConstants.alphanumericWithHyphenSpace
    },
    intervals: {
      0:'',
      4: errorMsg1
    },
    intervalsObj: {
      regexPattern: regexConstants.alphanumericWithHyphenSpace
    }
}
export const findingsHd = [
  { label: 'SR No', sortOption: false },
  { label: 'Section', sortOption: false },
  { label: 'Type of Damage', sortOption: false },
  { label: 'Description', sortOption: false },
  { label: 'Classification', sortOption: false },
  { label: 'Action', sortOption: false, actionCell: true }
];

export const aircraftStepIds = [
  {label:'Front',value:'2033a87b-b248-47eb-a99f-28fbe6ec2f0d'},
  {label:'Back',value:'6dc0b84f-4848-47b9-955c-686016be070e'},
  {label:'Top',value:'95084994-2845-47bc-b7f9-854528547c81'},
  {label:'Bottom',value:'5337d1ef-d874-46e2-a15b-bd30531fb5c2'},
  {label:'Left',value:'bbc50053-9204-4edb-b9be-791e9cd1ecdc'},
  {label:'Right',value:'10e5d5b2-6785-461a-885a-bab03ded36f2'},
  {label:'Top-Left',value:'c3e7f6d7-a3f4-49d6-9e6b-1b94517c871f'},
  {label:'Top-Right',value:'4ba34e22-f25f-46d1-bec0-066bcda9349f'},
  {label:'Bottom-Left',value:'9e2eb2d6-626f-4884-bb99-d6759b73efc5'},
  {label:'Bottom-Right',value:'3daefa7f-df39-421d-8ed2-fe126f0e5e4b'},
  {label:'Right-Inward',value:'57686463-2379-4786-ac4d-d242f523e997'},
  {label:'Left-Inward',value:'94aaa6bd-cae6-433d-9cc5-4e52486b6a5a'},
];

export const result = {
    "record": [
        {"last_hotspot_count": "1",
        "id": 10774,
                "section": {
                    "id": 1,
                    "name": "Left FWD Fuselage"
                },
                "deleted_at": null,
                "damage_dimension": null,
                "finding_type": null,
                "damage_limit": null,
                "arm_srm_reference": null,
                "dd_wo_reference": null,
                "remarks": null,
                "created_at": "2023-10-09T04:50:07.183938Z",
                "updated_at": "2023-10-09T04:50:07.184037Z",
                "corrective_actions": null,
                "classifications": null,
                "category": null,
                "repair_type": null,
                "intervals": null,
                "x_coordinate": null,
                "y_coordinate": null,
                "z_coordinate": null,
                "step_id": null,
                "step_type": null,
                "work_order": 2943,
                "position": "39.16901647319747m 345.0969571204767m 720.5087381252575m",
                "normal": "0.4382545242587696m 0.8988509175423474m 1.9958499687219241e-16m",

        },
        {
            "last_hotspot_count": "2",

            "position": "713.3107219550823m 536.1908913037067m 66.03952233830023m",
            "normal": "-0.09599675877085108m 0.995089873706672m 0.02409907782324107m",
            "id": 10775,
                "section": {
                    "id": 2,
                    "name": "Nose Gear & Wheel Well"
                },
                "deleted_at": null,
                "damage_dimension": null,
                "finding_type": null,
                "damage_limit": null,
                "arm_srm_reference": null,
                "dd_wo_reference": null,
                "remarks": null,
                "created_at": "2023-10-09T05:28:51.696269Z",
                "updated_at": "2023-10-09T05:28:51.696322Z",
                "corrective_actions": null,
                "classifications": null,
                "category": null,
                "repair_type": null,
                "intervals": null,
                "x_coordinate": null,
                "y_coordinate": null,
                "z_coordinate": null,
                "step_id": null,
                "step_type": null,
                "work_order": 2943
        }
    ],
    "metadata": {
        "id": "64f1c92fd972192679bd14a9",
        "private": true,
        "createdAt": "2023-09-01T11:21:19.322Z"
    }
}

import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import DatePicker from "react-datepicker";
import InfiniteScroll from 'react-infinite-scroll-component';
import {  UserInfoModal } from '../../Elements'
import { fetchProjectTrackerAc,downLoadTrackerFormAc, fetchProjectTrackerExpansionAc,updateRemarksAc } from '../actionCreators';
import FilterBar from '../../../../shared/FilterBar';
import { ToolTipHover, ListLoader, NoRecordFound,ExportFiles } from '../../../../shared';
import { sGlobalConstantAc } from '../../../../shared/actionCreators';
import { MonthAndDateHeader, ProjectDetail, StatusDetails, ProjectList } from '../components';
import { removeEmptyKey, getLocalStorageInfo } from '../../../../utils';
import { imgStoragePath, fieldDateFormat, displayDateFormat } from '../../../../constants';
import { getUserDetailAc } from '../../Projects/actionCreators';
import { CLOSE_USER_MODAL } from '../../Projects/actions';
import Switcher from '../../../../shared/components/Switcher';
import DeploymentMessage from '../../../../shared/components/DeploymentMessage';
let flag = 1;
class ProjectTracker extends Component {
  constructor(props){
		super(props);
		this.state = {
			months: [],
			positionHeader: 0,
			colWidth : 80,
			lhWidth: 0,
			windows:false,
			height: 0,
			appliedFilter: {},
			queryString: {},
      page:1,
		}
	}

  moveNextPage = () =>{
    this.setState({
      page: this.state.page + 1
    })
    this.props.fetchProjectTrackerExpansion( getLocalStorageInfo().user.account_type.includes('Acumen') ? {...this.state.queryString, page: this.state.page} : {...this.state.queryString, page: this.state.page , lessor_id: getLocalStorageInfo().defaultLessor.id})
  }

  componentDidMount(){
		this.props.fetchTrackerDetails( getLocalStorageInfo().user.account_type.includes('Acumen') ?{page: 1}:{page: 1, lessor_id: getLocalStorageInfo().defaultLessor.id });
    this.props.getTechConstants({constant_types: ['project_departments','project_name']})
		let lhWidth = document.body.contains(document.getElementById('lh-project-list')) ? document.getElementById('lh-project-list').clientHeight : 0 ;
		this.setState({
			lhWidth: lhWidth
		})
    this.setState({
       height : window.innerHeight - (41 + 78 + 112)
		});
		if(navigator.appVersion.indexOf("Win")!=-1){
      this.setState({
        windows : true
     });
		}
		document.querySelector('body').style.overflow = 'hidden';
	}

	getDates = (startDate, endDate) => {
		let dates=[], currentDate = moment(startDate);
		while(moment(endDate) >= moment(currentDate)){
			dates.push(moment(currentDate).format('YYYY-MM-DD'));
			currentDate = moment(currentDate).add('days', 1).format('YYYY-MM-DD');
		}
		return dates
	}

	getMonthWithDays = (startDate, endDate) => {
		let months = [], monthWithDays = [], currentDate=moment(startDate);
		while (moment(endDate) > moment(currentDate) || moment(currentDate).format('M') === moment(endDate).format('M')) {
		   months.push(currentDate.format('YYYY-MM'));
		   currentDate.add(1,'month');
		}
		months.map((month, index) => {
			if(index == 0){
				monthWithDays = [...monthWithDays, {month: moment(month).format('MMM'), year: moment(month).format('YYYY'), days: moment(month + '-'+moment(month, "YYYY-MM").daysInMonth(), 'YYYY-MM-DD').diff(moment(startDate, 'YYYY-MM-DD'), 'days', true)+1}];
			}else if(index == months.length-1){
				monthWithDays = [...monthWithDays, {month: moment(month).format('MMM'), year: moment(month).format('YYYY'), days: moment(endDate, 'YYYY-MM-DD').diff(moment(month+'-'+'01', 'YYYY-MM-DD'), 'days', true)+1}]
			}else{
				monthWithDays = [...monthWithDays, {month: moment(month).format('MMM'), year: moment(month).format('YYYY'), days: moment(month, "YYYY-MM").daysInMonth()}]
			}
		});
		return monthWithDays;

	}

	componentWillReceiveProps(nextProps){
		 this.generateMonth(moment(nextProps.projectTrackerDtls.start_date), moment(nextProps.projectTrackerDtls.end_date))
	}

	componentWillUnmount(){
    document.querySelector('body').style.overflow = 'auto';
  }

	clearFilterValue = (label) => {
    let filterParams = Object.assign({}, this.state.appliedFilter);
    delete filterParams[label];
    let getQueryStr = removeEmptyKey(this.getQueryStrfn(filterParams));
    this.props.fetchTrackerDetails(getLocalStorageInfo().user.is_root_user || (getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('GR'))?{...getQueryStr, page: 1}:{...getQueryStr, page: 1});
    this.setState({
			appliedFilter: filterParams,
			queryString:getQueryStr,
      page: 1
    });
	}
	changeFilterFn = (...args) => {
		let filterParams = Object.assign({}, this.state.appliedFilter);
    switch (args[0]) {
      case 'status':
        if(args[1]){
          let value = ''
          let label = ''
          args[1].map(item =>{
            value = value.length ? value + ',' + item.id.toString() : item.id.toString()
            label = label.length ? label + ',' + item.name : item.name
            return item
          })
          filterParams = {
            ...filterParams,
            [args[0]]: {
							name: 'Status ',
							value: label,
							filterData: value
            }
          }
        }
				break;
      case 'department':
        if(args[1]){
          let value = ''
          let label = ''
          args[1].map(item =>{
            value = value.length ? value + ',' + item.value.toString() : item.value.toString()
            label = label.length ? label + ',' + item.label : item.label
            return item
          })
          filterParams = {
            ...filterParams,
            [args[0]]: {
							name: 'Department ',
							value: label,
							filterData: value

            }
          }
        }
				break;
      case 'project_type':
        if(args[1]){
          let value = ''
          let label = ''
          args[1].map(item =>{
            value = value.length ? value + ',' + item.value.toString() : item.value.toString()
            label = label.length ? label + ',' + item.label : item.label
            return item
          })
          filterParams = {
            ...filterParams,
            [args[0]]: {
							name: 'Project Type ',
							value: label,
							filterData: label
            }
          }
        }
				break;
			case 'lessor_id':
        if(args[1]){
          filterParams = {
            ...filterParams,
            [args[0]]: {
							name: 'Lessor ',
							value: args[1].name,
							filterData: args[1].id

            }
          }
        }
				break;
			case 'search':
			if(args[1]){
				filterParams = {
					...filterParams,
					[args[0]]: {
						name: 'Search ',
						value: args[1],
						filterData: args[1]
					}
				}

			}
      break;
			case 'project_no':
			if(args[1]){
				filterParams = {
					...filterParams,
					[args[0]]: {
						name: 'Project No. ',
						value: args[1],
						filterData: args[1]
					}
				}

			}
			break;
			case 'serial_number':
			if(args[1]){
				filterParams = {
					...filterParams,
					[args[0]]: {
						name: 'Asset Serial Number ',
						value: args[1],
						filterData: args[1]
					}
				}

			}
			break;
			case 'operator':
			if(args[1]){
				filterParams = {
					...filterParams,
					[args[0]]: {
						name: 'Lessee ',
						value: args[1],
						filterData: args[1]
					}
				}

			}
			break;
			case 'project_lead':
			if(args[1]){
				filterParams = {
					...filterParams,
					[args[0]]: {
						name: 'Project Lead ',
						value: args[1],
						filterData: args[1]
					}
				}

			}
			break;
			case 'project_location':
			if(args[1]){
				filterParams = {
					...filterParams,
					[args[0]]: {
						name: 'Project Location ',
						value: args[1],
						filterData: args[1]
					}
				}

			}
			break;
			case 'project_engineer':
			if(args[1]){
				filterParams = {
					...filterParams,
					[args[0]]: {
						name: 'Project Engineer ',
						value: args[1],
						filterData: args[1]
					}
				}

			}
			break;
      case 'date':
        let date = (args[1] ? args[1]: '--') + ' To ' + (args[2]?args[2]:'--');
        filterParams = {
          ...filterParams,
          [args[0]]: {
            name:'Date Range ',
            value: date,
            startDate: args[1] ? args[1]:'',
            endDate: args[2]?args[2]:''
          }
				}

      default:
       break;
    }

    let getQueryStr = removeEmptyKey(this.getQueryStrfn(filterParams));
		this.props.fetchTrackerDetails(getLocalStorageInfo().user.is_root_user || (getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('GR'))?{...getQueryStr, page: 1}:{...getQueryStr, page: 1});
		this.setState({
      appliedFilter: filterParams,
			selectedFilterTitle: '',
			queryString:getQueryStr,
      page: 1
    });
  }
	getQueryStrfn = (params) => {
    let queryFilter = {};
    Object.keys(params).map(key => {
      if(key == 'date'){
        queryFilter['startDate'] = params[key].startDate
        queryFilter['endDate'] = params[key].endDate
      }else if(key == 'status'){
				queryFilter[key] = params[key].filterData

			}else{
        queryFilter[key] = params[key].filterData
      }
    });
    return queryFilter;
  }
	generateMonth = (startDate, endDate) => {
		let monthDiff = endDate.diff(startDate, 'months', true);
		var timeValues = [];
		while(endDate > startDate || startDate.format('M') === endDate.format('M')) {
			let obj = {};
			obj = {month: startDate.format('MMMM'), year: startDate.format('YYYY')};
				timeValues.push(obj);
				startDate.add(1,'month');
		}
		 this.setState(prevState => ({
			...prevState,
		 months: timeValues
	 }))
	}
	render(){
		const { projectTrackerDtls, exportTrackerLoader, trackerLoader } = this.props;
		const userInfo = getLocalStorageInfo();
		let filterMenu = {
      'Status': {
        'inputType': 'dropdown',
        'keyParam': 'status',
				'placeholder': 'Select status',
				'options': [ {id: 'upcoming', name: 'Up Coming'}, {id: 'ongoing', name: 'On Going'}, { id: 'overdue', name: 'Overdue'}, { id: 'completed', name: 'Completed'}],
        'labelKey': 'name',
        'valueKey': 'id',
        'multi' : true
			},
      'Department': {
        'inputType': 'dropdown',
        'keyParam': 'department',
				'placeholder': 'Select department',
				'options': this.props.techConstants.filter(item => item.type == 'project_departments'),
        'labelKey': 'label',
        'valueKey': 'value',
        'multi' : true
			},
      'Project Type': {
        'inputType': 'dropdown',
        'keyParam': 'project_type',
				'placeholder': 'Select project type',
				'options': this.props.techConstants.filter(item => item.type == 'project_name'),
        'labelKey': 'label',
        'valueKey': 'value',
        'multi' : true
			},
			'Search':{
        'inputType': 'text',
				'keyParam': 'search',
				'label': 'search'
      },
			'Project Lead':{
        'inputType': 'text',
				'keyParam': 'project_lead',
				'label': 'project lead'
      },
			'Project Engineer':{
        'inputType': 'text',
				'keyParam': 'project_engineer',
				'label': 'project engineer'
      },
			'Project Number':{
        'inputType': 'text',
				'keyParam': 'project_no',
				'label': 'project number'
      },
			'Project Location':{
        'inputType': 'text',
				'keyParam': 'project_location',
				'label': 'project location'
      },
			'Asset Serial Number':{
        'inputType': 'text',
				'keyParam': 'serial_number',
				'label': 'Asset Serial Number'
      },
      'Date Range': {
        'inputType': 'date',
        'keyParam': 'date'
      },
      'Lessee':{
        'inputType': 'text',
				'keyParam': 'operator',
				'label': 'operator'
      },
    };
		if( getLocalStorageInfo().user.is_root_user || (getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('GR'))){
			filterMenu = {
				...filterMenu,
				'Lessor ': {
	        'inputType': 'dropdown',
	        'keyParam': 'lessor_id',
					'placeholder': 'Select Lessor',
					'options': userInfo.lessor_list,
	        'labelKey': 'name',
	        'valueKey': 'id',
				}
			}
		}
		return(
			<div className="tracker-block">
				<div className="title-block">
					<DeploymentMessage />
            <h1>Trackers</h1>
            {getLocalStorageInfo().user.is_root_user || (getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('MR')) ? <p>Offers a view of the Projects status and Manpower status as applicable</p> : <p>Offers a view of the Projects status as applicable</p>}
        </div>
				<ul className="list-inline project-heading">
					<li className="list-inline-item">
						<Link className="active">PROJECT</Link>
					</li>
					{ getLocalStorageInfo().user.account_type === 'Acumen' && (getLocalStorageInfo().user.is_root_user || (getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('MR'))) ?
						<li className="list-inline-item">
						<Link to={!trackerLoader ? '/technical/man-power/tracker':''} >MAN POWER</Link>
					</li>:null
					}
					{/* { getLocalStorageInfo().user.account_type === 'Acumen' && (getLocalStorageInfo().user.is_root_user || (getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('CR'))) ?
					<li className="list-inline-item">
					<Link to={!trackerLoader ? '/technical/cor/tracker':''} >COR Tracker</Link>
			   	</li>:null
			   	} */}
				</ul>
				{exportTrackerLoader?<ListLoader/>:null}
				<div className="project-tracker">
					<div className="add-bulk-operations row" style={{margin:'0px 15px'}}>
            <div className="col-md-10">
              <ul className="list-inline flex-centered">
  							<li className="list-inline-item operation-cta">
  								<ExportFiles exportFile={(fileType) => this.props.projectTrackerExport('project-tracker',fileType,'ProjectTracker',this.state.queryString)} files={[{img:'xls_icon.png', label: 'XLS', type:'xls'}]} />
  							</li>

  						</ul>
            </div>
            <div className="col-md-2">
              <StatusDetails/>
            </div>
    			</div>
					<FilterBar
						appliedFilter={this.state.appliedFilter}
						clearFilterValue={this.clearFilterValue}
						filterMenu={filterMenu}
						changeFilterFn={this.changeFilterFn}
					/>


					{ trackerLoader ?<ListLoader/>
						:
						<div className="project-tracker-wrapper">
							<div className="flex-not-centered project-tracker-block header-sep-block">
								<div className="inner-width-block">
									<div className="row-header flex-not-centered" style={{ height: '74px'}}>
										<div id="lh-project-list" className="flex-not-centered spacing-cls project-heading-block text-right" style={{ flexFlowL: 'nowrap'}}>
											<div className="innerheading-block flex-centered" style={{ width:'242.5px', flexBasis: '242.5px'}}>
								   			<p className="para-ui ">Project Name</p>
											</div>
											<div className="innerheading-block flex-centered" style={{ width:'150px', flexBasis: '150px' }}>
								   			<p className="para-ui ">Asset</p>
											</div>
											<div className="innerheading-block flex-centered" style={{ width:'121px', flexBasis: '121px'}}>
								   			<p className="para-ui ">Status</p>
											</div>
											<div className="innerheading-block flex-centered" style={{ width:'121px', flexBasis: '121px'}}>
								   			<p className="para-ui ">Remarks</p>
											</div>
										</div>
										<div style={{ width: (window.innerWidth - 408) + 'px', overflow: 'hidden' }}>
											<MonthAndDateHeader
												months ={this.state.months}
												projectTrackerDtls={projectTrackerDtls}
												DateList = {this.getDates(projectTrackerDtls.start_date, projectTrackerDtls.end_date)}
												colWidth = {this.state.colWidth}
												lhWidth = {this.state.lhWidth}
											/>
										</div>
									</div>
								</div>
							</div>
							{ Object.keys(projectTrackerDtls).length ?
								<div style={{height: window.innerHeight - 290 + 'px', overflow:'auto', overflow: 'auto'}} className="scrollbar-hidden">
									{ projectTrackerDtls.projects.length ? null : <NoRecordFound/> }
                  <InfiniteScroll
                    dataLength={projectTrackerDtls.projects ? projectTrackerDtls.projects.length : 0} //This is important field to render the next data
                    next={() => this.moveNextPage()}
                    hasMore={projectTrackerDtls.projects && projectTrackerDtls.pagination && projectTrackerDtls.projects.length < projectTrackerDtls.pagination.total}
                    height={window.innerHeight - 310}
                    className="scrollbar-hidden inifinity-bottom"
                    >
					 				<div className="flex-not-centered project-range-block">
							 			<ProjectDetail
								 			projectTrackerDtls = {projectTrackerDtls.projects}
											 getUserDetailFn = {(slug, flag) => this.props.getUserDetailFn(slug, flag)}
											 updateRemarks={(slug, remarks)=> this.props.updateRemarks(slug, remarks)}
				 		 				/>
                  <div style={{ width: (window.innerWidth - (this.state.windows?535:505)) + 'px', overflow: 'auto'}}>
							 				<div className="inner-width-block" style={{width:  (window.innerWidth -  (this.state.windows?535:505)) + 'px'}}>
								 				{ projectTrackerDtls.projects.length ?
										 			projectTrackerDtls.projects.map((project, index) =>
                            <ProjectList colWidth={this.state.colWidth} project={project} projectTrackerDtls={projectTrackerDtls} index={index}/>
								 					): null}
					 						</div>
					 					</div>
				 					</div>
                  </InfiniteScroll>
			 					</div>
				  		: null
							}
						</div>
					}
				</div>
        {
          this.props.infiniteLoader ?
            <div style={{paddingLeft: '45%'}} className="data-room-expansion-loader"><img src={imgStoragePath + "spinner.gif"}/></div>
          :null
        }
				<UserInfoModal
					userDetail = {this.props.userDetail}
					closeUserDetailModal = {this.props.closeUserDetailModal}
				/>
			</div>
		)
	}
}
const mapStateToProps = (state) => ({
	projectTrackerDtls : state.TrackerReducer.projectTrackerDtls,
	infiniteLoader : state.TrackerReducer.infiniteLoader,
	trackerLoader: state.TrackerReducer.trackerLoader,
	userDetail: state.PorjectsReducer.userDetail,
	exportTrackerLoader:state.TrackerReducer.exportTrackerLoader,
  techConstants:state.sharedReducers.techConstants,
});
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		projectTrackerExport: (url,type,name,queryParam={}) =>dispatch(downLoadTrackerFormAc(url,type, name, queryParam)),
		fetchTrackerDetails : (queryParam={}) => dispatch(fetchProjectTrackerAc(ownProps, queryParam)),
		fetchProjectTrackerExpansion : (queryParam={}) => dispatch(fetchProjectTrackerExpansionAc(ownProps, queryParam)),
		getUserDetailFn : (userSlug, flag) => dispatch(getUserDetailAc(userSlug, flag)),
		updateRemarks : (projectSlug, value) => dispatch(updateRemarksAc(projectSlug, value)),
    closeUserDetailModal: (data, flag) => dispatch({
      type: CLOSE_USER_MODAL,
      payload: {data: data, flag: flag}
    }),
    getTechConstants:(data) => dispatch(sGlobalConstantAc(data))
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectTracker);

import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { DropzoneArea } from 'material-ui-dropzone';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, Grid, TextField, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core';
import { AutocompleteCreatable } from '../../../shared_elements'
import { checkApiStatus } from '../../../utils_v2';
import { globalGetService, globalPostService, globalPutService } from '../../../utils_v2/globalApiServices';
import { findingTypes, classifications, category, repairType } from '../'
const FindingCRU = ({params, addEditFinding, toggleModalFn, openAttachments, onChangeMode, getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [sections, setSections] = useState([])
    const [finding, setFinding] = useState(addEditFinding.data)
    useEffect(() => {
        getSections();
    },[]);
    const getSections = () => {
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/findings/sections/`)
        .then(response => {
            if(checkApiStatus(response)){
                setSections(response?.data?.data||[])
            }
        })
    }
    const onFieldChange = (key,value) => {
        setFinding({...finding, [key]:value})
    }
    const onAddEditFinding = () => {
        let validationInputs = {
            section:finding?.section ?'':'Please select Section',
            finding_type:finding?.finding_type?.trim()?.length ? '':'Please select Damage Type',
            remarks:finding?.finding_type?.trim()?.length ? '':'Please enter Description'
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            var formData = new FormData();
            Object.keys(finding).map((key) => {
                if(!['section', 'files'].includes(key)){
                    formData.append(key, finding[key]);
                }
                if(key === 'files' && finding.files.length){
                    for (let i = 0; i < finding.files.length; i++) {
                        let file = finding.files[i];
                        if (!file.type.match('image.*')) {
                            enqueueSnackbar(file.name + ' is not Valid Format', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                            continue;
                        }
                        formData.append('files[]', file, file.name);
                    }
                }
            })
            formData.append('section', JSON.stringify(finding.section));
            setLoading(true);
            if(finding.id){
                formData.delete("view_normal");
                formData.delete("view_position");
                globalPutService(`technical/v2/workorder/${params.workOrderSlug}/findings/${finding.id}/`,formData)
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                        toggleModalFn();getResponseBack();
                    }else{
                        enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                    }
                    setLoading(false);
                })
            }else{
                globalPostService(`technical/v2/workorder/${params.workOrderSlug}/findings/`,formData)
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                        toggleModalFn();getResponseBack();
                    }else{
                        enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                    }   
                    setLoading(false);
                })
            }
        }else{
            setError(validationInputs)
        }
    }
    return(
        <>
            <Dialog
                open={addEditFinding.modal}
                onClose={toggleModalFn}
                className='project-management-modal'
                maxWidth='md'
            >
                <DialogTitle id="scroll-dialog-title">
                    <div style={{display:'flex'}}>
                        <h2>Finding</h2>
                        { addEditFinding.mode === 'view' ?
                            <span style={{marginLeft:'auto', cursor:'pointer'}}>
                                <EditIcon onClick={() => onChangeMode('edit')} color='primary' fontSize="small" />
                            </span>:null
                        }
                    </div>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Grid container spacing={1}>
                        <Grid item md={4}>
                            <AutocompleteCreatable
                                options={sections}
                                paramsKey='section'
                                optionKey='name'
                                label='Aircraft Section'
                                value={finding?.section||null}
                                onFieldChange={(e, paramsKey, newValue) => onFieldChange('section', newValue)}
                                resetErrorKey={() => setError({...error,'section':''})}
                                error={error.section}
                                helperText={error.section}
                                required={true}
                            />
                            <div style={{display:'none'}}>
                                <Autocomplete
                                    disabled={addEditFinding.mode === 'view'}
                                    options={sections}
                                    getOptionLabel={option => option.name}
                                    id="section"
                                    value={finding?.section||null}
                                    renderInput={params => <TextField required error={error?.section} helperText={error?.section||''} {...params} label="Aircraft Section" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                    onChange={(e, value) => {onFieldChange('section', value); setError({...error,'section':''})}}
                                />
                            </div>
                            
                        </Grid>
                        <Grid item md={4}>
                            <Autocomplete
                                disabled={addEditFinding.mode === 'view'}
                                options={findingTypes}
                                getOptionLabel={option => option.label}
                                id="finding_type"
                                value={finding?.finding_type?.trim()?.length ? findingTypes.find(item => item.value === finding.finding_type) :null}
                                renderInput={params => <TextField required error={error?.finding_type} helperText={error?.finding_type||''} {...params} label="Damage Type" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                onChange={(e, value) => {onFieldChange('finding_type', value?value.value:null); setError({...error,'finding_type':''})}}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                disabled={addEditFinding.mode === 'view'}
                                id="damage_dimension"
                                label="Damage Dimension"
                                fullWidth
                                margin="normal"
                                value={finding?.damage_dimension||''}
                                inputProps={{ maxLength: 30 }}
                                InputLabelProps={{shrink: true}}
                                onChange={(e) => onFieldChange('damage_dimension', e.target.value)}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                disabled={addEditFinding.mode === 'view'}
                                id="damage_limit"
                                label="Damage Within Limit"
                                fullWidth
                                margin="normal"
                                value={finding?.damage_limit||''}
                                inputProps={{ maxLength: 30 }}
                                InputLabelProps={{shrink: true}}
                                onChange={(e) => onFieldChange('damage_limit', e.target.value)}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                disabled={addEditFinding.mode === 'view'}
                                required
                                id="remarks"
                                label="Description"
                                fullWidth
                                margin="normal"
                                value={finding?.remarks||''}
                                inputProps={{ maxLength: 30 }}
                                InputLabelProps={{shrink: true}}
                                onChange={(e) => onFieldChange('remarks', e.target.value)}
                                onFocus={() => setError({...error, 'remarks':''})}
                                error={error?.remarks} 
                                helperText={error?.remarks||''}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <Autocomplete
                                disabled={addEditFinding.mode === 'view'}
                                options={classifications}
                                getOptionLabel={option => option.label}
                                id="classifications"
                                value={finding?.classifications?.trim()?.length ? classifications.find(item => item.value === finding.classifications) :null}
                                renderInput={params => <TextField {...params} label="Classification" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                onChange={(e, value) => {onFieldChange('classifications', value?value.value:null);}}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <Autocomplete
                                disabled={addEditFinding.mode === 'view'}
                                options={category}
                                getOptionLabel={option => option.label}
                                id="category"
                                value={finding?.category?.trim()?.length ? category.find(item => item.value === finding.category) :null}
                                renderInput={params => <TextField {...params} label="Category" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                onChange={(e, value) => {onFieldChange('category', value?value.value:null);}}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <Autocomplete
                                disabled={addEditFinding.mode === 'view'}
                                options={repairType}
                                getOptionLabel={option => option.label}
                                id="repair_type"
                                value={finding?.repair_type?.trim()?.length ? repairType.find(item => item.value === finding.repair_type) :null}
                                renderInput={params => <TextField required {...params} label="Repair Type" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                onChange={(e, value) => {onFieldChange('repair_type', value?value.value:null);}}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                disabled={addEditFinding.mode === 'view'}
                                id="arm_srm_reference"
                                label="AMM / SRM Reference"
                                fullWidth
                                margin="normal"
                                value={finding?.arm_srm_reference||''}
                                inputProps={{ maxLength: 30 }}
                                InputLabelProps={{shrink: true}}
                                onChange={(e) => onFieldChange('arm_srm_reference', e.target.value)}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                disabled={addEditFinding.mode === 'view'}
                                id="dd_wo_reference"
                                label="D&D WO Reference"
                                fullWidth
                                margin="normal"
                                value={finding?.dd_wo_reference||''}
                                inputProps={{ maxLength: 30 }}
                                InputLabelProps={{shrink: true}}
                                onChange={(e) => onFieldChange('dd_wo_reference', e.target.value)}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                disabled={addEditFinding.mode === 'view'}
                                id="corrective_actions"
                                label="Corrective Action"
                                fullWidth
                                margin="normal"
                                value={finding?.corrective_actions||''}
                                inputProps={{ maxLength: 30 }}
                                InputLabelProps={{shrink: true}}
                                onChange={(e) => onFieldChange('corrective_actions', e.target.value)}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                disabled={addEditFinding.mode === 'view'}
                                id="intervals"
                                label="Threshold / Intervals"
                                fullWidth
                                margin="normal"
                                value={finding?.intervals||''}
                                inputProps={{ maxLength: 30 }}
                                InputLabelProps={{shrink: true}}
                                onChange={(e) => onFieldChange('intervals', e.target.value)}
                                variant='outlined'
                            />
                        </Grid>
                        { addEditFinding.mode !== 'view' ?
                            <Grid item md={12}>
                                <DropzoneArea
                                    filesLimit={10}
                                    maxFileSize={10000000}
                                    showPreviewsInDropzone={false}
                                    useChipsForPreview={true}
                                    showPreviews={true}
                                    acceptedFiles={['image/*']}
                                    dropzoneClass="drag-drop-cnt small-blk"
                                    onDrop={(files) => onFieldChange('files',files)}
                                    showAlerts={['error', 'info']}
                                />
                            </Grid>:null
                        }
                        { addEditFinding.mode === 'view' && finding?.images?.length ?
                            <Grid item md={12}>
                                <Button onClick={() => openAttachments(finding)} color='primary' size='small' variant="outlined">{`${finding.images.length} Attachment(s)`} </Button>
                            </Grid>:null
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size="small">Cancel</Button>
                    {addEditFinding.mode !== 'view' ?
                        <Button disabled={isLoading} onClick={onAddEditFinding} variant="contained" color="primary" size="small">
                            {isLoading ? <CircularProgress size={20} /> : 'SAVE' }
                        </Button>:null
                    }
                </DialogActions>
            </Dialog>
            
        </>
    )
}
export default withRouter(FindingCRU);
import React, { useState, Fragment } from 'react';
import { withRouter, Link } from 'react-router';
import { browserHistory } from 'react-router'
import { useSnackbar } from 'notistack';
import { Grid, Menu, MenuItem, TextField,Chip } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { checkApiStatus, localTimeFn ,onCheckFormPermission} from '../../../utils_v2';
import { globalPutService } from '../../../utils_v2/globalApiServices';
const IssueStatus = ({prsInfo, changeStatus,currentFormDetail}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (status) => {
        handleClose();
        changeStatus(status)
    };
    return(
        <Fragment>
            <span className={`issue-status`}>
            <span className="flex-centered">
            <Chip label={<span>{prsInfo?.status?.label || ''}{ onCheckFormPermission(currentFormDetail) ? <ArrowDropDownIcon   onClick={(event) => setAnchorEl(event.currentTarget)} fontSize="small" style={{ color: '#ffffff',float:'right' }} /> : null}</span>} 
              size="small"
              style={{  background:prsInfo?.status.value === 0 && '#4b81de' || prsInfo?.status.value === 1 && '#269b2c' || prsInfo?.status.value === 2 && '#e86642'|| prsInfo?.status.value === 3 && '#dbcc28', color: '#ffffff',width: '110px', display: 'flex',  alignItems: 'center' }}
            />
            </span>
            </span>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {[{ label: 'Open', value: 0 },{ label: 'Closed', value: 1 },{ label: 'Re-Open', value: 2 },{ label: 'Unresolved', value: 3 }].filter(item => {
                    if(prsInfo.status.value === 0){
                        return(item.value === 1 || item.value === 3)
                    }
                    if (prsInfo.status.value === 1) {
                        return (item.value === 2)
                    }
                    if(prsInfo.status.value === 2){
                        return (item.value === 1 || item.value === 3)
                    }    
                    if(prsInfo.status.value === 3){
                        return (item.value === 1 || item.value === 2) 
                    }           
                    return item.value !== prsInfo.status.value;
                }).map(item => (
                    <MenuItem key={item.value} onClick={() => handleClick(item)}>{item.label}</MenuItem>
                ))}
            </Menu>
        </Fragment>
    )
}
const IssueTitle = ({params,location, prsDetail,getResponseBack,currentFormDetail,getCurrentFormDetail}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [prsInfo, setPrsInfo] = useState(prsDetail)
    const onUpdatePrsInfo = (key, value, required) => {
        globalPutService(`technical/workorder/${params.workOrderSlug}/gap-report/${params.id}/?asset_type=${params.type}`, {gap_report_section:prsInfo.gap_report_section?.id, [key]:value})
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                getResponseBack()
                getCurrentFormDetail()
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        })
    }
    const backToList = () => {
        let location = browserHistory.getCurrentLocation()
        location = {
          ...location,
          pathname: `/${params.type}/${params.aircraft_slug}/technical/${params.workOrderSlug}/forms/${params.formSlug}/T011/`
        }
        browserHistory.push(location);
      }
    return(
        <div className='prs-title-info'>
            <ul className='list-inline flex-centered'>
                <li className='list-inline-item' onClick={() => backToList()} style={{cursor:'pointer'}}>
                    <p  className='flex-centered'><ArrowBackIcon color='primary' fontSize='small' /> <span>Issues(s)</span></p>
                </li>
                <li className='list-inline-item'>
                    <span>{prsInfo?.unique_code||''}</span>
                </li>
            </ul>
            <form>
                <TextField 
                    id="title"
                    placeholder='Issue Title'
                    fullWidth
                    margin="none"
                    value={prsInfo?.title || ''}
                    onChange={(e) => setPrsInfo({...prsInfo, title:e.target.value})}
                    onBlur={() => onUpdatePrsInfo('title', prsInfo.title, true)}
                    InputLabelProps={{ shrink: true }}
                    variant='outlined'
                    disabled={onCheckFormPermission(currentFormDetail) ? false : true}
                />
            </form>
            <ul className='list-inline user-info'>
                <li className='list-inline-item'>
                    <IssueStatus currentFormDetail={currentFormDetail} prsInfo={prsInfo} changeStatus={(data) => onUpdatePrsInfo('status', data.value, true)} />
                </li>
                <li className='list-inline-item'>
                    <span className='span-label'>Created By</span>: {prsInfo?.created_by?.name || '--'}
                </li>
                { prsInfo?.updated_by?.name ?
                    <li className='list-inline-item'>
                        <span className='span-label'>Last Updated By</span>: {prsInfo?.updated_by?.name||''} @ {localTimeFn(prsInfo?.updated_at||'')}
                    </li>:null
                }
            </ul>
        </div>
    )
}
export default withRouter(IssueTitle);